import { Contract } from "@ethersproject/contracts";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { createElement, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router";
import { mintable, StoredToken, tokens, Token as TokenType } from "../token_factory/TokenFactory";
import { networksByChainId, setNetwork } from "../utils/EthereumUtils";
import { TokenContract } from "./TokenContract";
import Style from "./tokenManager.module.css";
import abi from "./Abi";
import { Big } from "big.js";

const useQuery = () => new URLSearchParams(useLocation().search);
const e = createElement;

export default function Token() {
  const { address } = useParams<"address">();
  const query = useQuery();
  const urlChainId = Number(query.get("chainId"));
  const chain = networksByChainId[`0x${urlChainId.toString(16)}`];
  const { library, account, chainId } = useWeb3React<Web3Provider>();
  if (!library || !account || !chainId || !chain) {
    const err = new Error("something is falsy");
    (err as any).values = { library, account, chainId, chain };
    throw err;
  }
  const contract = useMemo(
    () => new Contract(address!, abi, library.getSigner()) as TokenContract,
    [address, library]
  );
  const [name, decimals, type] = useMemo(() => {
    const storedToken = (JSON.parse(window.localStorage.getItem("tokens")!) as StoredToken[]).find(
      t => t.address === address && t.chainId === urlChainId
    )!;
    return [
      storedToken.name,
      storedToken.decimals,
      tokens.find(type => type.name === storedToken.type)!,
    ];
  }, [address, urlChainId]);
  const showSupply = (s: string) =>
    Big(s)
      .div(10 ** decimals)
      .toFixed();
  const parseSupply = (s: string) =>
    Big(s)
      .mul(10 ** decimals)
      .toFixed();
  return urlChainId === chainId ? (
    <div className={"container " + Style.cont1}>
      <div className={"mb-2 " + Style.trans_cont}>
        <h3>Name: {name}</h3>
      </div>
      <div className={"mb-2 " + Style.trans_cont}>
        <h3>Address: {address}</h3>
      </div>
      {type.supplyType === "Capped" && e(Cap, { contract, showSupply })}
      {e(TotalSupplyMintBurn, { contract, contractType: type, showSupply, parseSupply })}
      <div className="row mb-1">
        {e(BalanceOf, { contract, showSupply })}
        {e(RecoverERC20, { contract, parseSupply })}
      </div>
      <div className="row mb-1">
        {e(Transfer, { contract, parseSupply })}
        {e(TransferAndCall, { contract, parseSupply })}
      </div>
      <div className="row">
        <TransferOwnership contract={contract} />
      </div>
      {mintable(type) && (
        <div className="row">
          <FinishMinting contract={contract} />
        </div>
      )}
      {type.pausable && <Pause contract={contract} />}
    </div>
  ) : (
    <button className="btn btn-outline-secondary" onClick={() => setNetwork(library, chain)}>
      Switch to {chain.chainName}
    </button>
  );
}

function BalanceOf({
  contract,
  showSupply,
}: {
  contract: TokenContract;
  showSupply: (s: string) => string;
}) {
  const [balance, setBalance] = useState<
    { t: "empty" | "loading" } | { t: "loaded"; value: string }
  >({ t: "empty" });
  const account = useInput();
  return (
    <div className={"col m-2 " + Style.trans_cont}>
      <div style={{ fontSize: "22px" }} className="row m-1 justify-content-md-center mb-3">
        Balance
      </div>
      <div className="row m-1">
        <label className="form-label">
          Írd az account mezőbe azt az addresst, melynek az egyenlegét le szeretnéd kérdezni.
        </label>
        <input className={"form-control mb-3 " + Style.inp1} placeholder="account" {...account} />
      </div>
      <div className="row m-1">
        <button
          className="btn btn-outline-secondary"
          onClick={() => {
            setBalance({ t: "loading" });
            contract.balanceOf(account.value).then(
              r => setBalance({ t: "loaded", value: r.toString() }),
              e => {
                setBalance({ t: "empty" });
                throw e;
              }
            );
          }}
          disabled={balance.t === "loading"}
        >
          Check balance
        </button>
      </div>
      <div>
        {balance.t === "loading" && "Loading..."}
        {balance.t === "loaded" && showSupply(balance.value)}
      </div>
    </div>
  );
}

function RecoverERC20({
  contract,
  parseSupply,
}: {
  contract: TokenContract;
  parseSupply: (s: string) => string;
}) {
  const [loading, setLoading] = useState(false);
  const tokenAddress = useInput();
  const tokenAmount = useInput();
  return (
    <div className={"col m-2 " + Style.trans_cont}>
      <div style={{ fontSize: "22px" }} className="row m-1 justify-content-md-center mb-3">
        Recover ERC-20 Token
      </div>
      <div className="row m-1">
        <input
          className={"form-control mb-3 " + Style.inp1}
          placeholder="tokenAddress"
          {...tokenAddress}
        />
      </div>
      <div className="row m-1">
        <input
          className={"form-control mb-3 " + Style.inp1}
          placeholder="tokenAmount"
          {...tokenAmount}
        />
      </div>
      <div className="row m-1">
        <button
          className="btn btn-outline-secondary"
          onClick={() => {
            setLoading(true);
            contract
              .recoverERC20(tokenAddress.value, parseSupply(tokenAmount.value))
              .finally(() => setLoading(false));
          }}
          disabled={loading}
        >
          Recover
        </button>
      </div>
      <div>{loading && "Loading..."}</div>
    </div>
  );
}

function TotalSupplyMintBurn({
  contract,
  contractType,
  showSupply,
  parseSupply,
}: {
  contract: TokenContract;
  contractType: TokenType;
  showSupply: (s: string) => string;
  parseSupply: (s: string) => string;
}) {
  const [totalSupply, setTotalSupply] = useState<
    { t: "error" | "loading" } | { t: "loaded"; value: string }
  >({ t: "loading" });
  const fetchTotalSupply = useCallback(() => {
    setTotalSupply({ t: "loading" });
    contract.totalSupply().then(
      r => setTotalSupply({ t: "loaded", value: r.toString() }),
      e => {
        setTotalSupply({ t: "error" });
        throw e;
      }
    );
  }, [contract]);
  useEffect(() => {
    fetchTotalSupply();
  }, [fetchTotalSupply]);
  return (
    <>
      <div className={`mb-2 ${Style.trans_cont}`}>
        <h3>
          Total supply:{" "}
          {(() => {
            switch (totalSupply.t) {
              case "error":
                return "Couldn't load";
              case "loading":
                return "Loading...";
              case "loaded":
                return showSupply(totalSupply.value);
            }
          })()}
        </h3>
      </div>
      <div className="row mb-1">
        {mintable(contractType) && e(Mint, { contract, fetchTotalSupply, parseSupply })}
        {contractType.burnable && e(Burn, { contract, fetchTotalSupply, parseSupply })}
      </div>
    </>
  );
}

function useInput() {
  const [value, setValue] = useState("");
  const onChange = ({ target: { value } }: { target: { value: string } }) => setValue(value);
  return { value, onChange };
}

function Transfer({
  contract,
  parseSupply,
}: {
  contract: TokenContract;
  parseSupply: (s: string) => string;
}) {
  const recipient = useInput();
  const amount = useInput();
  return (
    <div className={"col m-2 " + Style.trans_cont}>
      <div className="row m-1">
        <div style={{ fontSize: "22px" }} className="row m-1 justify-content-md-center mb-3">
          Simple transfer
        </div>
        <input
          className={"form-control  mb-3 " + Style.inp1}
          placeholder="recipient"
          {...recipient}
        />
      </div>
      <div className="row m-1">
        <input className={"form-control  mb-3 " + Style.inp1} placeholder="amount" {...amount} />
      </div>
      <div className="row m-1">
        <button
          className="btn btn-outline-secondary"
          onClick={() => contract.transfer(recipient.value, parseSupply(amount.value))}
        >
          Transfer
        </button>
      </div>
    </div>
  );
}

function TransferAndCall({
  contract,
  parseSupply,
}: {
  contract: TokenContract;
  parseSupply: (s: string) => string;
}) {
  const recipient = useInput();
  const amount = useInput();
  const data = useInput();
  return (
    <div className={"col m-2 " + Style.trans_cont}>
      <div className="row m-1">
        <div style={{ fontSize: "22px" }} className="row m-1 justify-content-md-center mb-3">
          Transfer with data
        </div>
        <input
          className={"form-control  mb-3 " + Style.inp1}
          placeholder="recipient"
          {...recipient}
        />
      </div>
      <div className="row m-1">
        <input className={"form-control  mb-3 " + Style.inp1} placeholder="amount" {...amount} />
      </div>
      <div className="row m-1">
        <input className={"form-control  mb-3 " + Style.inp1} placeholder="data" {...data} />
      </div>
      <div className="row m-1">
        <button
          className="btn btn-outline-secondary"
          onClick={() =>
            contract["transferAndCall(address,uint256,bytes)"](
              recipient.value,
              parseSupply(amount.value),
              data.value
            )
          }
        >
          Transfer
        </button>
      </div>
    </div>
  );
}

function TransferOwnership({ contract }: { contract: TokenContract }) {
  const newOwner = useInput();
  return (
    <div className={"col m-2 " + Style.trans_cont}>
      <div style={{ fontSize: "22px" }} className="row m-1 justify-content-md-center mb-3">
        Ownership
      </div>
      <div className="row m-1">
        <input
          className={"form-control  mb-3 " + Style.inp1}
          placeholder="newOwner"
          {...newOwner}
        />
      </div>
      <div className="row m-1">
        <button
          className="btn btn-outline-secondary"
          onClick={() => contract.transferOwnership(newOwner.value)}
        >
          Transfer ownership
        </button>
      </div>
    </div>
  );
}

function Burn({
  contract,
  fetchTotalSupply,
  parseSupply,
}: {
  contract: TokenContract;
  fetchTotalSupply: () => void;
  parseSupply: (s: string) => string;
}) {
  const amount = useInput();
  return (
    <div className={"col m-2 " + Style.trans_cont}>
      <div style={{ fontSize: "22px" }} className="row m-1 justify-content-md-center mb-3">
        Burn tokens
      </div>
      <div className="row m-1">
        <input className={"form-control  mb-3 " + Style.inp1} placeholder="amount" {...amount} />
      </div>
      <div className="row m-1">
        <button
          className="btn btn-outline-secondary"
          onClick={() =>
            contract
              .burn(parseSupply(amount.value))
              .then(r => r.wait())
              .then(fetchTotalSupply)
          }
        >
          Burn
        </button>
      </div>
    </div>
  );
}

function Pause({ contract }: { contract: TokenContract }) {
  const [paused, setPaused] = useState<
    { t: "error" | "loading" } | { t: "loaded"; value: boolean }
  >({ t: "loading" });
  useEffect(() => {
    contract.paused().then(
      value => setPaused({ t: "loaded", value }),
      e => {
        setPaused({ t: "error" });
        throw e;
      }
    );
  }, [contract]);
  return (
    <>
      <div className={`mb-2 ${Style.trans_cont}`}>
        <h3>
          Paused:{" "}
          {(() => {
            switch (paused.t) {
              case "error":
                return "Couldn't load";
              case "loading":
                return "Loading...";
              case "loaded":
                return paused.value.toString();
            }
          })()}
        </h3>
      </div>
      <div className="row mb-1">
        <div className={"col m-2 " + Style.trans_cont}>
          <div style={{ fontSize: "22px" }} className="row m-1 justify-content-md-center mb-3">
            Pause token
          </div>
          <div className="row m-1">
            <button
              className="btn btn-outline-secondary"
              onClick={() =>
                contract
                  .pause()
                  .then(t => t.wait())
                  .then(() => setPaused({ t: "loaded", value: true }))
              }
            >
              Pause
            </button>
          </div>
        </div>
        <div className={"col m-2 " + Style.trans_cont}>
          <div style={{ fontSize: "22px" }} className="row m-1 justify-content-md-center mb-3">
            Unpause token
          </div>
          <div className="row m-1">
            <button
              className="btn btn-outline-secondary"
              onClick={() =>
                contract
                  .unpause()
                  .then(t => t.wait())
                  .then(() => setPaused({ t: "loaded", value: false }))
              }
            >
              Unpause
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function Mint({
  contract,
  fetchTotalSupply,
  parseSupply,
}: {
  contract: TokenContract;
  fetchTotalSupply: () => void;
  parseSupply: (s: string) => string;
}) {
  const account = useInput();
  const amount = useInput();
  return (
    <div className={"col m-2 " + Style.trans_cont}>
      <div style={{ fontSize: "22px" }} className="row m-1 justify-content-md-center mb-3">
        Mint token
      </div>
      <div className="row m-1">
        <input className={"form-control  mb-3 " + Style.inp1} placeholder="account" {...account} />
      </div>
      <div className="row m-1">
        <input className={"form-control  mb-3 " + Style.inp1} placeholder="amount" {...amount} />
      </div>
      <div className="row m-1">
        <button
          className="btn btn-outline-secondary"
          onClick={() =>
            contract
              .mint(account.value, parseSupply(amount.value))
              .then(r => r.wait())
              .then(fetchTotalSupply)
          }
        >
          Mint
        </button>
      </div>
    </div>
  );
}

function FinishMinting({ contract }: { contract: TokenContract }) {
  return (
    <div className={"col m-2 " + Style.trans_cont}>
      <div style={{ fontSize: "22px" }} className="row m-1 justify-content-md-center mb-3">
        Finish Minting
      </div>
      <div className="row m-1">
        <button className="btn btn-outline-secondary" onClick={() => contract.finishMinting()}>
          Finish
        </button>
      </div>
    </div>
  );
}

function Cap({
  contract,
  showSupply,
}: {
  contract: TokenContract;
  showSupply: (s: string) => string;
}) {
  const [cap, setCap] = useState<{ t: "error" | "loading" } | { t: "loaded"; value: string }>({
    t: "loading",
  });
  useEffect(() => {
    contract.cap().then(
      r => setCap({ t: "loaded", value: r.toString() }),
      e => {
        setCap({ t: "error" });
        throw e;
      }
    );
  }, [contract]);
  return (
    <div className={`mb-2 ${Style.trans_cont}`}>
      <h3>
        Cap:{" "}
        {(() => {
          switch (cap.t) {
            case "error":
              return "Couldn't load";
            case "loading":
              return "Loading...";
            case "loaded":
              return showSupply(cap.value);
          }
        })()}
      </h3>
    </div>
  );
}
