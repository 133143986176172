// eslint-disable
import { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";

import { BigNumber } from "ethers";
import { WeightedMultiOwner } from "./contract";
import { partition, prop } from "ramda";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { useSnackbar } from "notistack";
import Style from "./Multisig.module.css";

type Transaction = {
  index: number;
  createdBy: string;
  destination: string;
  value: BigNumber;
  data: string;
  executed: boolean;
  voteWeight: BigNumber;
  confirmedBySender: boolean;
};

/**
 * @example
 * // returns [0, 1, 2, 3, 4]
 * range(5)
 */
const range = (toExcluse: number) => [...Array(toExcluse).keys()];

function AddTransactionForm({
  contract,
  updateTransactions,
}: {
  contract: WeightedMultiOwner;
  updateTransactions: () => Promise<void>;
}) {
  const [showForm, setShowForm] = useState(false);
  const [savingForm, setSavingForm] = useState(false);
  const [destination, setDestination] = useState("");
  const [value, setValue] = useState("");
  const [data, setData] = useState("");
  return (
    <>
      <div className="col text-center" style={{ paddingTop: "0px" }}>
        <button
          onClick={() => setShowForm(true)}
          className="btn btn-outline-secondary"
          style={{ width: "200px" }}
        >
          + Submit transaction
        </button>
      </div>
      <Modal
        isOpen={showForm}
        onRequestClose={() => setShowForm(false)}
        className="container-fluid txcont"
        style={{ content: { display: "flex", justifyContent: "center" } }}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            setSavingForm(true);
            contract
              .submitTransaction(destination.trim(), value.trim(), data.trim())
              .then(t => t.wait())
              .finally(() => {
                setSavingForm(false);
                setShowForm(false);
              })
              .then(updateTransactions);
          }}
        >
          <div className="row" style={{ textAlign: "center" }}>
            <label className="translabel"> Please fill in the following transaction data! </label>
          </div>
          <div className="row">
            <label className="translabel"> Destination </label>
            <input
              onChange={e => setDestination(e.target.value)}
              disabled={savingForm}
              pattern="\s*0[x][0-9a-fA-F]{40}\s*"
              placeholder="0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
              required
              title="Ethereum address"
              type="text"
              value={destination}
            />
          </div>
          <div className="row">
            <label className="translabel">Value (Wei)</label>
            <input
              onChange={e => setValue(e.target.value)}
              disabled={savingForm}
              pattern="\s*\d+\s*"
              placeholder="0"
              required
              title="Non negative integer"
              type="string"
              value={value}
            />
          </div>
          <div className="row">
            <label className="translabel"> Data </label>
            <input
              className="transinp"
              onChange={e => setData(e.target.value)}
              disabled={savingForm}
              pattern="\s*0x([0-9a-fA-F][0-9a-fA-F])+\s*"
              placeholder="0x00"
              required
              title="Hexadecimal data with even number of digits"
              type="string"
              value={data}
            />
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <input
              className="transbutton"
              type="submit"
              value={savingForm ? "Saving..." : "Save"}
              disabled={savingForm}
            />
          </div>
        </form>
      </Modal>
    </>
  );
}

function AddOwnerForm({
  contract,
  updateTransactions,
}: {
  contract: WeightedMultiOwner;
  updateTransactions: () => Promise<void>;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const addError = (e: string) => enqueueSnackbar(e, { variant: "error" });
  const [showForm, setShowForm] = useState(false);
  const [savingForm, setSavingForm] = useState(false);
  const [address, setAddress] = useState("");
  const [weight, setWeight] = useState("");
  return (
    <>
      <div className="col text-center" style={{ paddingTop: "0px" }}>
        <button
          onClick={() => setShowForm(true)}
          className="btn btn-outline-secondary"
          style={{ width: "200px" }}
        >
          Add owner
        </button>
      </div>
      <Modal
        isOpen={showForm}
        onRequestClose={() => setShowForm(false)}
        className="container-fluid txcont"
        style={{ content: { display: "flex", justifyContent: "center" } }}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            try {
              const data = contract.interface.encodeFunctionData("addOwner", [
                address.trim(),
                weight,
              ]);
              setSavingForm(true);
              contract
                .submitTransaction(contract.address, 0, data)
                .then(t => t.wait())
                .finally(() => {
                  setSavingForm(false);
                  setShowForm(false);
                })
                .then(updateTransactions);
            } catch (e: any) {
              addError(e.message);
            }
          }}
        >
          <div className="row" style={{ textAlign: "center" }}>
            <label className="translabel"> Please fill in the following transaction data! </label>
          </div>
          <div className="row">
            <label className="translabel"> Address </label>
            <input
              onChange={e => setAddress(e.target.value)}
              disabled={savingForm}
              pattern="\s*0[x][0-9a-fA-F]{40}\s*"
              placeholder="0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
              required
              title="Ethereum address"
              type="text"
              value={address}
            />
          </div>
          <div className="row">
            <label className="translabel">Weight</label>
            <input
              onChange={e => setWeight(e.target.value)}
              disabled={savingForm}
              placeholder="1"
              required
              title="Non negative integer"
              type="number"
              step="1"
              min="1"
              max="255"
              value={weight}
            />
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <input
              className="transbutton"
              type="submit"
              value={savingForm ? "Saving..." : "Save"}
              disabled={savingForm}
            />
          </div>
        </form>
      </Modal>
    </>
  );
}

function RemoveOwnerForm({
  contract,
  updateTransactions,
}: {
  contract: WeightedMultiOwner;
  updateTransactions: () => Promise<void>;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const addError = (e: string) => enqueueSnackbar(e, { variant: "error" });
  const [showForm, setShowForm] = useState(false);
  const [savingForm, setSavingForm] = useState(false);
  const [address, setAddress] = useState("");
  return (
    <>
      <div className="col text-center" style={{ paddingTop: "0px" }}>
        <button
          onClick={() => setShowForm(true)}
          className="btn btn-outline-secondary"
          style={{ width: "200px" }}
        >
          Remove owner
        </button>
      </div>
      <Modal
        isOpen={showForm}
        onRequestClose={() => setShowForm(false)}
        className="container-fluid txcont"
        style={{ content: { display: "flex", justifyContent: "center" } }}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            try {
              const data = contract.interface.encodeFunctionData("removeOwner", [address.trim()]);
              setSavingForm(true);
              contract
                .submitTransaction(contract.address, 0, data)
                .then(t => t.wait())
                .finally(() => {
                  setSavingForm(false);
                  setShowForm(false);
                })
                .then(updateTransactions);
            } catch (e: any) {
              addError(e.message);
            }
          }}
        >
          <div className="row" style={{ textAlign: "center" }}>
            <label className="translabel"> Please fill in the following transaction data! </label>
          </div>
          <div className="row">
            <label className="translabel"> Address </label>
            <input
              onChange={e => setAddress(e.target.value)}
              disabled={savingForm}
              pattern="\s*0[x][0-9a-fA-F]{40}\s*"
              placeholder="0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
              required
              title="Ethereum address"
              type="text"
              value={address}
            />
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <input
              className="transbutton"
              type="submit"
              value={savingForm ? "Saving..." : "Save"}
              disabled={savingForm}
            />
          </div>
        </form>
      </Modal>
    </>
  );
}

function ChangeOwnerWeightForm({
  contract,
  updateTransactions,
}: {
  contract: WeightedMultiOwner;
  updateTransactions: () => Promise<void>;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const addError = (e: string) => enqueueSnackbar(e, { variant: "error" });
  const [showForm, setShowForm] = useState(false);
  const [savingForm, setSavingForm] = useState(false);
  const [address, setAddress] = useState("");
  const [weight, setWeight] = useState("");
  return (
    <>
      <div className="col text-center" style={{ paddingTop: "0px" }}>
        <button
          onClick={() => setShowForm(true)}
          className="btn btn-outline-secondary"
          style={{ width: "200px" }}
        >
          Change owner weight
        </button>
      </div>
      <Modal
        isOpen={showForm}
        onRequestClose={() => setShowForm(false)}
        className="container-fluid txcont"
        style={{ content: { display: "flex", justifyContent: "center" } }}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            try {
              const data = contract.interface.encodeFunctionData("changeOwnerWeight", [
                address.trim(),
                weight,
              ]);
              setSavingForm(true);
              contract
                .submitTransaction(contract.address, 0, data)
                .then(t => t.wait())
                .finally(() => {
                  setSavingForm(false);
                  setShowForm(false);
                })
                .then(updateTransactions);
            } catch (e: any) {
              addError(e.message);
            }
          }}
        >
          <div className="row" style={{ textAlign: "center" }}>
            <label className="translabel"> Please fill in the following transaction data! </label>
          </div>
          <div className="row">
            <label className="translabel"> Address </label>
            <input
              onChange={e => setAddress(e.target.value)}
              disabled={savingForm}
              pattern="\s*0[x][0-9a-fA-F]{40}\s*"
              placeholder="0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
              required
              title="Ethereum address"
              type="text"
              value={address}
            />
          </div>
          <div className="row">
            <label className="translabel">Weight</label>
            <input
              onChange={e => setWeight(e.target.value)}
              disabled={savingForm}
              placeholder="1"
              required
              title="Non negative integer"
              type="number"
              step="1"
              min="1"
              max="255"
              value={weight}
            />
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <input
              className="transbutton"
              type="submit"
              value={savingForm ? "Saving..." : "Save"}
              disabled={savingForm}
            />
          </div>
        </form>
      </Modal>
    </>
  );
}

function ChangeRequirementForm({
  contract,
  updateTransactions,
}: {
  contract: WeightedMultiOwner;
  updateTransactions: () => Promise<void>;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const addError = (e: string) => enqueueSnackbar(e, { variant: "error" });
  const [showForm, setShowForm] = useState(false);
  const [savingForm, setSavingForm] = useState(false);
  const [weight, setWeight] = useState("");
  return (
    <>
      <div className="col text-center" style={{ paddingTop: "0px" }}>
        <button
          onClick={() => setShowForm(true)}
          className="btn btn-outline-secondary"
          style={{ width: "200px" }}
        >
          Change requirement
        </button>
      </div>
      <Modal
        isOpen={showForm}
        onRequestClose={() => setShowForm(false)}
        className="container-fluid txcont"
        style={{ content: { display: "flex", justifyContent: "center" } }}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            try {
              const data = contract.interface.encodeFunctionData("changeRequirement", [weight]);
              setSavingForm(true);
              contract
                .submitTransaction(contract.address, 0, data)
                .then(t => t.wait())
                .finally(() => {
                  setSavingForm(false);
                  setShowForm(false);
                })
                .then(updateTransactions);
            } catch (e: any) {
              addError(e.message);
            }
          }}
        >
          <div className="row" style={{ textAlign: "center" }}>
            <label className="translabel"> Please fill in the following transaction data! </label>
          </div>
          <div className="row">
            <label className="translabel">Weight</label>
            <input
              onChange={e => setWeight(e.target.value)}
              disabled={savingForm}
              placeholder="1"
              required
              title="Positive integer"
              type="number"
              min="1"
              value={weight}
            />
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <input
              className="transbutton"
              type="submit"
              value={savingForm ? "Saving..." : "Save"}
              disabled={savingForm}
            />
          </div>
        </form>
      </Modal>
    </>
  );
}

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

type Transactions = {
  all: Transaction[];
  pending: Transaction[];
  executed: Transaction[];
  required: BigNumber;
  ownerWeights: (readonly [string, number])[];
};

type TransactionFilter = "all" | "pending" | "executed";

const TransactionTableHeader = ({ filter }: { filter: TransactionFilter }) => (
  <>
    <th className="th text-center" scope="col">
      Information
    </th>
    <th className="th text-center" scope="col">
      Destination
    </th>
    <th className="th text-center" scope="col">
      Confirmation
    </th>
    {filter === "all" && (
      <th className="th text-center" scope="col">
        Status
      </th>
    )}
    {filter !== "executed" && (
      <th className="th text-center" scope="col">
        Action
      </th>
    )}
  </>
);

const TransactionTableRows = ({
  transactions,
  filter,
  contract,
  updateTransactions,
  setTransactions,
}: {
  transactions: Transactions;
  filter: TransactionFilter;
  contract: WeightedMultiOwner;
  updateTransactions: () => Promise<void>;
  setTransactions: (n: null) => void;
}) => (
  <>
    {transactions[filter].map(t => (
      <tr key={t.index}>
        <td style={{ maxWidth: "400px", maxHeight: "400px" }}>
          <div className={"row " + Style.rowborder}>
            <div className="col-sm text-center" style={{ border: "none" }}>
              <div className="title bold">Transaction id:</div>
            </div>
            <div className="col-sm text-center" style={{ border: "none" }}>
              <div className="record">{t.index}</div>
            </div>
          </div>
          <hr className={Style.nomargin} />
          <div className="row">
            <div className="col-sm text-center" style={{ border: "none" }}>
              <div className="title bold">Transaction value:</div>
            </div>
            <div className="col-sm text-center" style={{ border: "none" }}>
              <div className="record text-center">{t.value.toString()} ILG</div>
            </div>
          </div>
          <hr className={Style.nomargin} />
          <div className="row">
            <div className="col-sm text-center" style={{ border: "none" }}>
              <div className="title bold">Data:</div>
            </div>
            <div className="col-sm text-center" style={{ border: "none" }}>
              <div className="record">{t.data}</div>
            </div>
          </div>
        </td>
        <td className={"td1 " + Style.vert}>
          <div className="align-middle">
            <div className="row record bold dest text-center m-2">Destination:</div>
            <div className="row record dest text-center m-2">{t.destination}</div>
            <div className="row record bold dest text-center m-2">Submitted by:</div>
            <div className="row record dest text-center m-2">{t.createdBy}</div>
          </div>
        </td>
        <td className={"td1 " + Style.vert}>
          <div className="col text-center">
            {t.voteWeight.toString()}
            {t.executed ? "" : `/${transactions.required.toString()}`}
          </div>
        </td>
        {filter === "all" && (
          <td className={"td1 " + Style.vert}> {t.executed ? "Executed" : "Pending"}</td>
        )}
        {filter !== "executed" && (
          <td className={"td1 " + Style.vert}>
            {!t.executed &&
              (t.confirmedBySender ? (
                <button
                  className="btn btn-primary btn-md"
                  onClick={() => {
                    setTransactions(null);
                    contract
                      .revokeConfirmation(t.index)
                      .then(t => t.wait())
                      .finally(updateTransactions);
                  }}
                >
                  - Revoke
                </button>
              ) : (
                <button
                  className="btn btn-primary btn-md"
                  onClick={() => {
                    setTransactions(null);
                    contract
                      .confirmTransaction(t.index)
                      .then(t => t.wait())
                      .finally(updateTransactions);
                  }}
                >
                  + Confirm
                </button>
              ))}
          </td>
        )}
      </tr>
    ))}
  </>
);

export default function TransactionsComponent({
  contract,
  owners,
}: {
  contract: WeightedMultiOwner;
  owners: string[];
}) {
  const { account } = useWeb3React<Web3Provider>();
  const [transactions, setTransactions] = useState<null | {
    all: Transaction[];
    pending: Transaction[];
    executed: Transaction[];
    required: BigNumber;
    ownerWeights: (readonly [string, number])[];
  }>(null);
  const updateTransactions = useCallback(
    (): Promise<void> =>
      Promise.all([
        contract.required(),
        contract
          .getTransactionsLength()
          .then(count =>
            Promise.all(
              range(count.toNumber()).map(index =>
                contract.getTransaction(index).then(t => ({ ...t, index }))
              )
            )
          ),
        Promise.all(
          owners.map(owner =>
            contract.getOwnerWeight(owner).then(weight => [owner, weight] as const)
          )
        ),
      ]).then(([required, all, ownerWeights]) => {
        const [executed, pending] = partition(prop("executed"), all);
        setTransactions({ all, pending, executed, required, ownerWeights });
      }),
    [contract, owners]
  );
  useEffect(() => {
    updateTransactions().then();
  }, [updateTransactions, account]);

  type Tab = TransactionFilter | "owners";
  const [filter, setFilter] = useState<Tab>("all");
  const FilterTab = ({ _filter }: { _filter: Tab }) =>
    transactions && (
      <div
        className={
          "col text-center " +
          (filter === _filter ? " chosen " : "") +
          (filter === _filter ? Style.bold : "")
        }
        onClick={() => setFilter(_filter)}
        style={{ cursor: "pointer" }}
      >
        {capitalize(_filter)}
        {_filter !== "owners" && <>({transactions[_filter].length})</>}
      </div>
    );
  return (
    <>
      <div
        className={"col m-2 align-self-center " + Style.trans_cont}
        style={transactions === null ? { display: "none" } : { maxWidth: "700px" }}
      >
        <div className="row justify-content-center mb-3">
          <AddTransactionForm
            contract={contract}
            updateTransactions={() => {
              setTransactions(null);
              return updateTransactions();
            }}
          />
          <AddOwnerForm
            contract={contract}
            updateTransactions={() => {
              setTransactions(null);
              return updateTransactions();
            }}
          />
          <RemoveOwnerForm
            contract={contract}
            updateTransactions={() => {
              setTransactions(null);
              return updateTransactions();
            }}
          />
        </div>
        <div className="row align-content-center">
          <ChangeOwnerWeightForm
            contract={contract}
            updateTransactions={() => {
              setTransactions(null);
              return updateTransactions();
            }}
          />
          <ChangeRequirementForm
            contract={contract}
            updateTransactions={() => {
              setTransactions(null);
              return updateTransactions();
            }}
          />
          <div className="col text-center" style={{ paddingTop: "0px" }}></div>
        </div>
      </div>
      {transactions === null ? (
        <div>fetching transactions</div>
      ) : (
        <>
          <div className="row" style={{ padding: "20px" }}>
            <hr className="mt-4" />
            <FilterTab _filter="all" />
            <FilterTab _filter="pending" />
            <FilterTab _filter="executed" />
            <FilterTab _filter="owners" />
            <hr className="mt-4" />
          </div>
          <table className="table">
            <thead className="thead-dark thead">
              <tr>
                {filter === "owners" ? (
                  <>
                    <th className="th text-center" scope="col">
                      Address
                    </th>
                    <th className="th text-center" scope="col">
                      Weight
                    </th>
                  </>
                ) : (
                  <TransactionTableHeader filter={filter} />
                )}
              </tr>
            </thead>
            <tbody>
              {filter === "owners" ? (
                transactions.ownerWeights.map(([address, weight]) => (
                  <tr>
                    <td className="text-center">{address}</td>
                    <td className="text-center">{weight}</td>
                  </tr>
                ))
              ) : (
                <TransactionTableRows
                  contract={contract}
                  filter={filter}
                  setTransactions={setTransactions}
                  transactions={transactions}
                  updateTransactions={updateTransactions}
                />
              )}
            </tbody>
          </table>
        </>
      )}
    </>
  );
}
