import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { ReactNode, ReducerAction, useMemo, useReducer } from "react";
import Big from "big.js";
import Style from "./bridge.module.css";
import {
  networks,
  Eip747Asset,
  Eip3085Chain,
  HexString,
  setNetwork,
  networksByChainId,
} from "../utils/EthereumUtils";
import { identity } from "fp-ts/lib/function";
import convertImg from "./resources/convert.png";
import { Contract } from "@ethersproject/contracts";
import { BigNumber } from "@ethersproject/bignumber";
import { GroupSelector, MultiMap } from "../GroupSelector";
import Loader from "../components/loader";
const qmark = "/images/question_mark.png";

const tokens = {
  bnb: {
    wilg: identity<Eip747Asset>({
      type: "ERC20",
      options: {
        address: "0x8c01dFbCBCa328F3B379957BD93B992388EdcbEB",
        symbol: "WILG",
        decimals: 18,
        image: "https://www.ilgon.com/images/ilgon_coin.png",
      },
    }),
  },
} as const;

type Bridge = Readonly<
  {
    bridge: HexString;
    taxInWei: number;
    destination: {
      chain: Eip3085Chain;
    };
  } & (
    | {
        source: "native";
        destination: {
          asset: Eip747Asset;
        };
      }
    | {
        source: Eip747Asset;
        destination: {
          asset: "native";
        };
      }
  )
>;
const bridges = new Map<Eip3085Chain, readonly [Bridge, ...Bridge[]]>([
  [
    networks.ilg,
    [
      {
        source: "native",
        destination: {
          chain: networks.bnb,
          asset: tokens.bnb.wilg,
        },
        bridge: "0x8c01dFbCBCa328F3B379957BD93B992388EdcbEB",
        taxInWei: 10 * 1e18,
      },
    ],
  ],
  [
    networks.bnb,
    [
      {
        source: tokens.bnb.wilg,
        destination: {
          chain: networks.ilg,
          asset: "native",
        },
        bridge: "0x142c2b17f7AB576992Df8198d3aF0BD0Aa023BA3",
        taxInWei: 10 * 1e18,
      },
    ],
  ],
]);

function throw_(error = new Error()): never {
  throw error;
}

type BridgeState = { t: "loading" } | NetworkNotSelectedState | NetworkSelectedState;

type NetworkNotSelectedState = { t: "noSelected"; transactionHistory: TransactionHistory };

type NetworkSelectedState = {
  t: "selected";
  sourceNetwork: Eip3085Chain;
  sourceAsset: Bridge["source"];
  balanceEth: string;
  bridge: Bridge;
  input: string;
  transactionCost: string;
  transactionHistory: TransactionHistory;
};

const reducer = (
  state: BridgeState,
  action: (prevState: BridgeState) => BridgeState
): BridgeState => action(state);

type TransactionHistory = {
  sourceChain: Eip3085Chain;
  bridge: Bridge;
  amount: string;
  at: Date;
}[];
function TransactionHistoryTable({ state }: { state: BridgeState }) {
  const Th = ({ children }: { children: ReactNode }) => (
    <th style={{ textAlign: "center" }}>{children}</th>
  );
  return (
    <div className="col-md-6 m-3 h-100">
      <div className={Style.cont2}>
        <h3 className="col align-self-center text-center mb-4">Token transaction history</h3>
        {state.t === "loading" ? (
          <div
            style={{ marginLeft: "2px", marginRight: "2px" }}
            className={"row justify-content-center p-4 " + Style.trans_cont}
          >
            <Loader />
          </div>
        ) : (
          <table className={"h-100 table table-hover table-dark " + Style.txhist}>
            <thead>
              <tr>
                <th />
                <Th>Amount</Th>
                <Th>At</Th>
              </tr>
            </thead>
            <tbody>
              {state.transactionHistory.map(({ sourceChain, bridge, amount, at }, i) => (
                <tr key={i}>
                  <td>
                    {getSourceSymbol(sourceChain, bridge)} ({sourceChain.chainName}) -{"> "}
                    {getTargetSymbol(bridge)} ({bridge.destination.chain.chainName})
                  </td>
                  <td>{amount}</td>
                  <td>{at.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

const getImage = (chain: Eip3085Chain, asset: "native" | Eip747Asset) =>
  asset === "native" ? chain.iconUrls![0] : asset.options.image;

const getSymbol = (chain: Eip3085Chain, asset: "native" | Eip747Asset) =>
  (asset === "native" ? chain.nativeCurrency : asset.options).symbol;

const getSourceSymbol = (chain: Eip3085Chain, bridge: Bridge) => getSymbol(chain, bridge.source);

const getTargetSymbol = (bridge: Bridge) =>
  getSymbol(bridge.destination.chain, bridge.destination.asset);

const getDecimals = (chain: Eip3085Chain, asset: "native" | Eip747Asset) =>
  (asset === "native" ? chain.nativeCurrency : asset.options).decimals;

const getBalance = (
  chain: Eip3085Chain,
  source: Eip747Asset | "native",
  provider: Web3Provider,
  account: string
): Promise<string> =>
  (source === "native"
    ? provider.getBalance(account)
    : (new Contract(
        source.options.address,
        [
          {
            constant: true,
            inputs: [
              {
                name: "_owner",
                type: "address",
              },
            ],
            name: "balanceOf",
            outputs: [
              {
                name: "balance",
                type: "uint256",
              },
            ],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
        ],
        provider
      ).balanceOf(account) as Promise<BigNumber>)
  ).then(b =>
    Big(b.toString())
      .div(10 ** getDecimals(chain, source))
      .toFixed()
  );

export default function BridgeComponent() {
  const { library, account } = useWeb3React<Web3Provider>();
  if (!library || !account) {
    throw new Error();
  }
  const [state, dispatch] = useReducer(reducer, { t: "loading" }, () => ({
    t: "noSelected" as const,
    transactionHistory: getTransactionHistory(),
  }));
  const setBridge = (chain: Eip3085Chain, bridge: Bridge, input: string) =>
    setNetwork(library, chain)
      .then(() => {
        const provider = getProvider();
        return Promise.all([
          getBalance(chain, bridge.source, provider, account),
          getTansactionCost(provider, bridge).then(t => t.toString()),
        ]);
      })
      .then(([balanceEth, transactionCost]) =>
        dispatch(() => ({
          t: "selected",
          sourceNetwork: chain,
          sourceAsset: bridge.source,
          balanceEth,
          bridge,
          input,
          transactionCost,
          transactionHistory: getTransactionHistory(),
        }))
      );
  return (
    <div className="row justify-content-evenly">
      <div className={"col m-3 ml-3 me-0 " + Style.cont1}>
        <div className="row justify-content-md-center mb-4">
          <div className={"col-1 " + Style.tooltip}>
            <img src={qmark} width="30" alt="" />
            <span className={Style.tooltiptext}>
              Select the blockchains, that you want to bridge your coins betweens, then enter the
              amount you wish to transfer.
            </span>
          </div>
          <div className="col-10">
            <h3 className="text-center m-0 ">Send tokens between different blockchains</h3>
          </div>
          <div className="col-1"></div>
        </div>
        <div className={"col align-self-center p-4 " + Style.trans_cont}>
          <div className="row justify-content-center">
            {state.t === "loading" ? (
              <Loader />
            ) : (
              <>
                <div
                  style={{ color: "white", fontSize: "14px", textAlign: "center" }}
                  className="row justify-content-center mb-3"
                >
                  <div style={{ padding: "0", fontSize: "20px" }} className="col">
                    From
                  </div>
                  <div style={{ padding: "0", fontSize: "20px" }} className="col">
                    To
                  </div>
                </div>
                <div
                  style={{ color: "white", fontSize: "14px", textAlign: "center" }}
                  className="d-flex flex-column flex-xl-row justify-content-center mb-4"
                >
                  <SourceSelector state={state} setBridge={setBridge} dispatch={dispatch} />
                  {state.t !== "noSelected" && (
                    <>
                      <div
                        style={{
                          color: "white",
                          fontSize: "14px",
                          textAlign: "center",
                          maxWidth: "70px",
                        }}
                        className={"col-md-2 align-self-center " + Style.disappear}
                      >
                        <SwapDirection state={state} setBridge={setBridge} dispatch={dispatch} />
                      </div>
                      <DestinationSelector
                        state={state}
                        setBridge={setBridge}
                        dispatch={dispatch}
                      />
                    </>
                  )}
                </div>
                {state.t !== "noSelected" && (
                  <>
                    <Info title="Current Account balance">{state.balanceEth}</Info>
                    <div
                      style={{ color: "white", fontSize: "14px", textAlign: "center" }}
                      className="row jusfify-content-center mb-3"
                    >
                      <form
                        onSubmit={e => {
                          dispatch(() => ({ t: "loading" }));
                          e.preventDefault();
                          const amountSent = BigNumber.from(
                            Big(state.input)
                              .mul(10 ** getDecimals(state.sourceNetwork, state.sourceAsset))
                              .plus(state.bridge.taxInWei)
                              .toFixed()
                          );
                          const updateHistroy = () => {
                            localStorage.setItem(
                              "transactionHistory",
                              JSON.stringify([
                                identity<LocalTransactionHistory>({
                                  source: {
                                    chain: state.sourceNetwork.chainId,
                                    asset: getSourceSymbol(state.sourceNetwork, state.bridge),
                                  },
                                  target: {
                                    chain: state.bridge.destination.chain.chainId,
                                    asset: getTargetSymbol(state.bridge),
                                  },
                                  amount: state.input,
                                  at: Date.now(),
                                }),
                                ...getLocalTransactionHistory(),
                              ])
                            );
                            dispatch(() => ({
                              ...state,
                              transactionHistory: getTransactionHistory(),
                            }));
                          };
                          (async () => {
                            const signer = library.getSigner();
                            await signer.getChainId().then(c => {
                              if (c !== parseInt(state.sourceNetwork.chainId, 16)) {
                                throw new Error(
                                  "The selected network and the source network differs"
                                );
                              }
                            });
                            if (state.sourceAsset === "native") {
                              const t = await signer.sendTransaction({
                                to: state.bridge.bridge,
                                value: amountSent,
                              });
                              return t.wait();
                            } else {
                              const erc20Abi = [
                                {
                                  inputs: [
                                    { internalType: "address", name: "recipient", type: "address" },
                                    { internalType: "uint256", name: "amount", type: "uint256" },
                                  ],
                                  name: "transfer",
                                  outputs: [{ internalType: "bool", name: "", type: "bool" }],
                                  stateMutability: "nonpayable",
                                  type: "function",
                                },
                              ];
                              const tokenContract = new Contract(
                                state.sourceAsset.options.address,
                                erc20Abi,
                                signer
                              );
                              return tokenContract.transfer(
                                state.bridge.bridge,
                                amountSent
                              ) as Promise<void>;
                            }
                          })()
                            .catch(e => {
                              dispatch(() => state);
                              throw e;
                            })
                            .then(updateHistroy);
                        }}
                      >
                        <div className="row">
                          <input
                            className={Style.inp1}
                            type="number"
                            required
                            min={1 / 10 ** getDecimals(state.sourceNetwork, state.sourceAsset)}
                            step={1 / 10 ** getDecimals(state.sourceNetwork, state.sourceAsset)}
                            placeholder="Enter amount"
                            value={state.input}
                            onChange={e => dispatch(() => ({ ...state, input: e.target.value }))}
                          />
                        </div>
                        <div className="row mb-4 justify-content-center">
                          <div className={"col align-self-center " + Style.btnbox}>
                            <button
                              type="button"
                              className={"btn " + Style.maxbtn}
                              onClick={() =>
                                dispatch(() => ({
                                  ...state,
                                  input: Big(state.balanceEth)
                                    .minus(
                                      Big(state.transactionCost)
                                        .plus(state.bridge.taxInWei)
                                        .div(
                                          10 ** getDecimals(state.sourceNetwork, state.sourceAsset)
                                        )
                                    )
                                    .toFixed(),
                                }))
                              }
                            >
                              max
                            </button>
                          </div>
                        </div>
                        <Info title="Final amount with swap fee and gas fee">
                          {state.input &&
                            Big(state.transactionCost)
                              .plus(state.bridge.taxInWei)
                              .div(10 ** getDecimals(state.sourceNetwork, state.sourceAsset))
                              .plus(state.input)
                              .toFixed()}
                        </Info>
                        <div className="row">
                          <button style={{ color: "white" }} className="btn btn-outline-secondary">
                            Send
                          </button>
                        </div>
                      </form>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <TransactionHistoryTable state={state} />
    </div>
  );
}

function Info({ title, children }: { title: string; children: string }) {
  return (
    <div
      style={{ color: "white", fontSize: "14px", textAlign: "center" }}
      className="row jusfify-content-center mb-4"
    >
      <div
        style={{ backgroundColor: "#383848", borderRadius: "10px" }}
        className="col align-self-center"
      >
        <div className="row mb-4">
          <div style={{ textAlign: "center", marginTop: "10px" }} className={Style.whitefont}>
            {title}:
          </div>
        </div>
        <hr />
        <div style={{ textAlign: "center", paddingBottom: "10px" }} className={Style.whitefont}>
          {children}
        </div>
      </div>
    </div>
  );
}
type Dispatch = React.Dispatch<ReducerAction<typeof reducer>>;

async function getTansactionCost(provider: Web3Provider, bridge: Bridge) {
  return bridge.source === "native"
    ? Promise.all([
        provider.estimateGas({
          to: bridge.bridge,
          value: BigNumber.from(1),
        }),
        provider.getGasPrice(),
      ]).then(([gas, gasPrice]) => gas.mul(gasPrice))
    : Promise.resolve(BigNumber.from(0));
}

const getLocalTransactionHistory = () =>
  JSON.parse(localStorage.getItem("transactionHistory") ?? "[]") as LocalTransactionHistory[];

type LocalTransactionHistory = {
  source: { chain: string; asset: string };
  target: { chain: string; asset: string };
  amount: string;
  at: number;
};

const getTransactionHistory = (): TransactionHistory =>
  getLocalTransactionHistory().flatMap(hist => {
    const sourceChain = networksByChainId[hist.source.chain];
    const targetChain = networksByChainId[hist.target.chain];
    const bridge = bridges
      .get(sourceChain)
      ?.find(
        b =>
          getSourceSymbol(sourceChain, b) === hist.source.asset &&
          b.destination.chain === targetChain &&
          getTargetSymbol(b) === hist.target.asset
      );
    return bridge
      ? [
          {
            sourceChain,
            bridge,
            amount: hist.amount,
            at: new Date(hist.at),
          },
        ]
      : [];
  });

function SourceSelector({
  state,
  setBridge,
  dispatch,
}: {
  state: NetworkSelectedState | NetworkNotSelectedState;
  setBridge: (c: Eip3085Chain, b: Bridge, input: string) => Promise<void>;
  dispatch: Dispatch;
}) {
  const sources: MultiMap<Eip3085Chain, "native" | Eip747Asset> = [...bridges].map(
    ([chain, bs]) => [chain, [...new Set(bs.map(b => b.source))]]
  );
  return (
    <GroupSelector
      values={sources}
      Button={() => (
        <button className={"btn form-control " + Style.sel} data-bs-toggle="dropdown">
          {state.t !== "noSelected" ? (
            <>
              <img
                className="pe-2"
                style={{ height: "1.5rem" }}
                alt=""
                src={getImage(state.sourceNetwork, state.sourceAsset)}
              />
              {getSymbol(state.sourceNetwork, state.sourceAsset)} ({state.sourceNetwork.chainName})
            </>
          ) : (
            <>Select the source</>
          )}
        </button>
      )}
      DropdownContainer={({ children }) => (
        <ul style={{ backgroundColor: "#383848", color: "white" }} className="dropdown-menu">
          {children}
        </ul>
      )}
      SeparatorComponent={() => (
        <li style={{ backgroundColor: "#383848", color: "white" }}>
          <hr className="dropdown-divider" />
        </li>
      )}
      GroupComponent={({ group: chain }) => (
        <li style={{ backgroundColor: "#383848", color: "white" }}>
          <label className="px-3">{chain.chainName}</label>
        </li>
      )}
      ValueComponent={({ group: chain, value: source }) => (
        <li className={Style.drop1}>
          <div
            style={{ color: "white" }}
            className={"dropdown-item " + Style.drop1}
            id="drop1"
            onClick={() => {
              dispatch(() => ({ t: "loading" }));
              const input = state.t === "noSelected" ? "" : state.input;
              const bridge = bridges.get(chain)!.find(b => b.source === source) ?? throw_();
              setBridge(chain, bridge, input).catch(e => {
                dispatch(() => state);
                throw e;
              });
            }}
          >
            <img
              className="pe-2"
              style={{ height: "1.5rem" }}
              alt=""
              src={getImage(chain, source)}
            />
            {getSymbol(chain, source)}
          </div>
        </li>
      )}
    />
  );
}

const getProvider = () => new Web3Provider((window as any).ethereum, "any");

function SwapDirection({
  state,
  setBridge,
  dispatch,
}: {
  state: NetworkSelectedState;
  setBridge: (c: Eip3085Chain, b: Bridge, input: string) => Promise<void>;
  dispatch: Dispatch;
}) {
  return (
    <button
      className={"btn mb-2 mt-2 " + Style.converbtn}
      onClick={() => {
        dispatch(() => ({ t: "loading" }));
        const newBridge =
          bridges
            .get(state.bridge.destination.chain)!
            .find(
              ({ source, destination }) =>
                source === state.bridge.destination.asset &&
                destination.chain === state.sourceNetwork &&
                destination.asset === state.bridge.source
            ) ?? throw_();
        setBridge(state.bridge.destination.chain, newBridge, state.input).catch(e => {
          dispatch(() => state);
          throw e;
        });
      }}
    >
      <img className={Style.convert} src={convertImg} alt="" />
    </button>
  );
}

function DestinationSelector({
  state,
  dispatch,
  setBridge,
}: {
  state: NetworkSelectedState;
  dispatch: Dispatch;
  setBridge: (c: Eip3085Chain, b: Bridge, input: string) => Promise<void>;
}) {
  const destinations = useMemo(
    () => [
      ...bridges
        .get(state.sourceNetwork)!
        .reduce(
          (bs, b) =>
            b.source === state.sourceAsset
              ? bs.set(b.destination.chain, [...(bs.get(b.destination.chain) ?? []), b])
              : bs,
          new Map<Eip3085Chain, readonly [Bridge, ...Bridge[]]>()
        ),
    ],
    [state.sourceNetwork, state.sourceAsset]
  );
  return (
    <GroupSelector
      values={destinations}
      Button={() => (
        <button className={"btn form-control " + Style.sel} data-bs-toggle="dropdown">
          <img
            className="pe-2"
            style={{ height: "1.5rem" }}
            alt=""
            src={getImage(state.bridge.destination.chain, state.bridge.destination.asset)}
          />
          {getSymbol(state.bridge.destination.chain, state.bridge.destination.asset)} (
          {state.bridge.destination.chain.chainName})
        </button>
      )}
      DropdownContainer={({ children }) => (
        <ul style={{ backgroundColor: "#383848", color: "white" }} className="dropdown-menu">
          {children}
        </ul>
      )}
      SeparatorComponent={() => (
        <li>
          <hr className="dropdown-divider" />
        </li>
      )}
      GroupComponent={({ group }) => (
        <li>
          <label className="px-3">{group.chainName}</label>
        </li>
      )}
      ValueComponent={({ group: chain, value: b }) => (
        <li className={Style.drop1}>
          <div
            className={"dropdown-item " + Style.drop1}
            onClick={() => {
              dispatch(() => ({ t: "loading" }));
              setBridge(state.sourceNetwork, b, state.input).catch(e => {
                dispatch(() => state);
                throw e;
              });
            }}
          >
            <img
              className="pe-2"
              style={{ height: "1.5rem" }}
              alt=""
              src={getImage(chain, b.destination.asset)}
            />
            {getSymbol(chain, b.destination.asset)}
          </div>
        </li>
      )}
    />
  );
}
