import { useNavigate } from "react-router-dom";
import { StoredToken } from "../token_factory/TokenFactory";
import { networksByChainId } from "../utils/EthereumUtils";
import Style from "./tokenManager.module.css";

export default function TokenManager() {
  return (
    <div className={"container " + Style.main_cont}>
      <div className={"container " + Style.table1}>
        <h1 className="mb-3">Token Manager</h1>
        <TokenTable />
      </div>
    </div>
  );
}
function TokenTable() {
  const history = useNavigate();
  const tokens = JSON.parse(window.localStorage.getItem("tokens") ?? "[]") as StoredToken[];
  return (
    <table className="table table-dark table-hover">
      <thead>
        <tr>
          <th>Address</th>
          <th>Type</th>
          <th>Symbol</th>
          <th>Decimals</th>
          <th>Chain</th>
          <th>Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {tokens.map(t => (
          <tr key={t.address}>
            <td>{t.address}</td>
            <td>{t.type}</td>
            <td>{t.symbol}</td>
            <td>{t.decimals}</td>
            <td>{networksByChainId[`0x${t.chainId.toString(16)}`].chainName}</td>
            <td>{t.name}</td>
            <td>
              <button
                className="btn btn-outline-secondary"
                onClick={() => history(`/token-manager/${t.address}?chainId=${t.chainId}`)}
              >
                Manage
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
