import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { useEffect, useMemo, useState } from "react";

import { WeightedMultiOwner__factory } from "./contract";
import Transactions from "./transactions";
import Loader from "../components/loader";
import { BigNumber, Contract } from "ethers";
import { parseEther, defaultAbiCoder } from "ethers/lib/utils";
import Style from "./Multisig.module.css";
import { networks } from "../utils/EthereumUtils";
import { ClonerContract } from "./contract/ClonerContract";
import { throw_ } from "../utils/Utils";
const qmark = "/images/question_mark.png";
const clonerContracts = {
  [networks.ilgt.chainId]: {
    cloner: "0x591B924d1EBa2C87fbe78C63E9a87F40A281C204",
    multisig: "0x65c357852CfdF7FAB53d478Fe49f60050050B36f",
  },
};

type ShowContractProps = {
  address: string;
  account: string;
  library: Web3Provider;
};
function ShowContract({ address, account, library }: ShowContractProps) {
  const contract = useMemo(
    () => WeightedMultiOwner__factory.connect(address, library!.getSigner()),
    [address, library]
  );
  const [owners, setOwners] = useState<string[] | null>(null);
  const [requiredConfirmations, setRequiredConfirmations] = useState<BigNumber | null>(null);
  useEffect(() => {
    setOwners(null);
    setRequiredConfirmations(null);
    contract.getOwners().then(setOwners);
    contract.required().then(setRequiredConfirmations);
  }, [contract]);
  return (
    <>
      <div className="row" style={{ paddingLeft: "20px" }}>
        Required confirmations: {requiredConfirmations ? requiredConfirmations.toString() : "..."}
      </div>
      {(() => {
        if (owners === null) {
          return <div>checking if owner</div>;
        } else if (owners.includes(account!)) {
          return <Transactions contract={contract} owners={owners} />;
        } else {
          return <div>you are not an owner</div>;
        }
      })()}
    </>
  );
}

type AddAddressProps = { onAddressAdd: (name: string, address: string) => void };
function AddAddress({ onAddressAdd }: AddAddressProps) {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  return (
    <div className={"col m-2 align-self-center mb-3 " + Style.trans_cont}>
      <div className="row mb-3">
        <div
          className={
            "col-2 d-flex flex-wrap align-items-center justify-content-center " + Style.tooltip
          }
        >
          <img src={qmark} width="25" alt="" />
          <span className={Style.tooltiptext}>
            You can add an existing contract for further management to this interface, by providing
            its name and contract address.
          </span>
        </div>
        <div style={{ fontWeight: "bold", fontSize: "24px" }} className="col-8 text-center">
          Add existing contract
        </div>
        <div className="col-2"></div>
      </div>
      <form
        className="col"
        onSubmit={e => {
          e.preventDefault();
          onAddressAdd(name, address);
        }}
        style={{ display: "flex" }}
      >
        <div className="container">
          <div className="row m-1 justify-content-md-center mb-3">
            <input
              type="text"
              className={"form-control " + Style.inp1}
              placeholder="Name"
              required
              value={name}
              pattern="\s*\S+\s*"
              title="At least on non whitespace character"
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="row m-1 justify-content-md-center mb-3">
            <input
              type="text"
              className={"form-control " + Style.inp1}
              placeholder="Address"
              required
              pattern="\s*0[x][0-9a-fA-F]{40}\s*"
              title="Ethereum address"
              value={address}
              onChange={e => setAddress(e.target.value)}
            />
          </div>
          <div className="row m-1 justify-content-md-center mb-3">
            <input
              style={{ width: "100px" }}
              type="submit"
              className="btn btn-outline-secondary"
              value="Add"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

type NewContractProps = {
  onAddressAdd: (name: string, address: string) => void;
  contractAlreadyExists: (name: string) => boolean;
};
function NewContract({ onAddressAdd, contractAlreadyExists }: NewContractProps) {
  const { library, chainId } = useWeb3React<Web3Provider>();
  const chainIdStr = `0x${chainId!.toString(16)}`;
  type State =
    | {
        t: "editing";
        name: string;
        nameError: string | null;
        owners: string;
        ownersError: string | null;
        confirmations: string;
        confirmationsError: string | null;
      }
    | { t: "loading" };
  const [state, setState] = useState<State>({
    t: "editing",
    name: "",
    nameError: null,
    owners: "",
    ownersError: null,
    confirmations: "",
    confirmationsError: null,
  });
  return (
    <div className={"col m-2 align-self-center mb-3 " + Style.trans_cont}>
      <div className="row mb-3">
        <div
          className={
            "col-2 d-flex flex-wrap align-items-center justify-content-center " + Style.tooltip
          }
        >
          <img src={qmark} width="25" alt="" />
          <span className={Style.tooltiptext}>
            You can create a new contract by giving it a name, setting its owners and their voting
            weights and the number of required confirmations.
          </span>
        </div>
        <div
          style={{ fontWeight: "bold", fontSize: "24px" }}
          className="col-8 d-flex flex-wrap align-items-center justify-content-center"
        >
          Create new contract
        </div>
        <div className="col-2"></div>
      </div>
      {state.t === "loading" ? (
        <Loader />
      ) : (
        <form
          noValidate
          onSubmit={e => {
            e.preventDefault();
            const name = state.name.trim();
            const nameError = (() => {
              if (name === "") {
                return "Required";
              } else if (contractAlreadyExists(name)) {
                return "There is already a contract with that name";
              } else {
                return null;
              }
            })();
            const parsedOwners: string | { owners: string[]; weights: string[] } =
              parseOwners(state);
            const confirmations = state.confirmations.trim();
            const confirmationsError = (() => {
              if (!confirmations.match(/^[1-9]+\d*$/)) {
                return "Must be a positive integer";
              } else if (Number(confirmations) >= 256) {
                return "Must be less than 256";
              } else {
                return null;
              }
            })();
            if (nameError || typeof parsedOwners === "string" || confirmationsError) {
              setState({
                ...state,
                nameError,
                ownersError: typeof parsedOwners === "string" ? parsedOwners : null,
                confirmationsError,
              });
            } else {
              const ownersError =
                parsedOwners.weights.reduce((acc, w) => acc + Number(w), 0) < Number(confirmations)
                  ? "The sum of ownerWeights must be at least confirmations"
                  : null;
              if (ownersError != null) {
                setState({
                  ...state,
                  nameError: null,
                  ownersError,
                  confirmationsError: null,
                });
              } else {
                setState({ t: "loading" });
                (
                  new Contract(
                    (
                      clonerContracts[chainIdStr] ??
                      throw_(new Error("Missing implementation contracts"))
                    ).cloner,
                    [
                      {
                        inputs: [
                          {
                            internalType: "address",
                            name: "implementation",
                            type: "address",
                          },
                        ],
                        name: "clone",
                        outputs: [
                          {
                            internalType: "address",
                            name: "instance",
                            type: "address",
                          },
                        ],
                        stateMutability: "nonpayable",
                        type: "function",
                      },
                    ],
                    library!.getSigner()
                  ) as ClonerContract
                )
                  .clone(clonerContracts[chainIdStr].multisig)
                  .then(addr => addr.wait())
                  .then(async receipt => {
                    const contract = WeightedMultiOwner__factory.connect(
                      defaultAbiCoder.decode(["address"], receipt.events![0].data)[0],
                      library!.getSigner()
                    );
                    await contract
                      .intialize(
                        parsedOwners.owners,
                        parsedOwners.weights,
                        confirmations,
                        "0x288cb755bD8Ef6aF16602F932Bd020D2C6706608",
                        {
                          value: parseEther("1"),
                        }
                      )
                      .then(transaction => transaction.wait());
                    return contract.address;
                  })
                  .then(address => onAddressAdd(name, address))
                  .catch(e => {
                    setState({
                      ...state,
                      nameError: null,
                      ownersError: null,
                      confirmationsError: null,
                    });
                    throw e;
                  });
              }
            }
          }}
        >
          <div className="row m-1 justify-content-md-center text-center">
            <label className="form-label">Name</label>
          </div>
          <div className="row m-1 justify-content-md-center mb-3">
            <div className="input-group has-validation">
              <input
                className={`form-control ${state.nameError ? "is-invalid" : ""} ` + Style.inp1}
                placeholder="Name"
                value={state.name}
                onChange={e => setState({ ...state, name: e.target.value })}
              />
              <div className="invalid-feedback">{state.nameError}</div>
            </div>
          </div>
          <div className="row m-1 justify-content-md-center text-center">
            <label className="form-label">Initial owners and weights</label>
          </div>
          <div className="row m-1 justify-content-md-center mb-3">
            <div className="input-group has-validation">
              <textarea
                className={`form-control ${state.ownersError ? "is-invalid" : ""} ` + Style.inp1}
                placeholder={
                  "0x0000000000000000000000000000000000000000,1\n0x0000000000000000000000000000000000000001,2"
                }
                value={state.owners}
                onChange={e => setState({ ...state, owners: e.target.value })}
                rows={6}
                style={{ fontFamily: "monospace" }}
              />
              <div className="invalid-feedback">{state.ownersError}</div>
            </div>
          </div>
          <div className="row m-1 justify-content-md-center  text-center">
            <label className="form-label">Number of required confirmations.</label>
          </div>
          <div className="row m-1 justify-content-md-center mb-3">
            <div className="input-group has-validation">
              <input
                className={
                  `form-control ${state.confirmationsError ? "is-invalid" : ""} ` + Style.inp1
                }
                placeholder="Confirmations"
                value={state.confirmations}
                onChange={e => setState({ ...state, confirmations: e.target.value })}
              />
              <div className="invalid-feedback">{state.confirmationsError}</div>
            </div>
          </div>
          <div className="row m-1 justify-content-md-center mb-3">
            <input
              style={{ width: "100px" }}
              type="submit"
              className="btn btn-outline-secondary"
              value="Create"
            />
          </div>
        </form>
      )}
    </div>
  );
}

type ContractName = string;
type ContractAddress = string;
type ChainId = number;
type ChainIdToContracts = Record<ChainId, Record<ContractName, ContractAddress>>;

export default function Multisig() {
  const [allDefaultContracts, setAllDefaultContracts] = useState<ChainIdToContracts | null>(null);
  useEffect(() => {
    fetch("/DefaultContracts.json")
      .then(r => r.json())
      .then(setAllDefaultContracts);
  }, []);
  return (
    <div className={"container " + Style.cont1}>
      {allDefaultContracts ? (
        <DefaultContractsLoaded allDefaultContracts={allDefaultContracts} />
      ) : (
        <div>loading default contracts</div>
      )}
    </div>
  );
}

type DefaultContractsLoadedProps = {
  allDefaultContracts: ChainIdToContracts;
};
function parseOwners(state: { owners: string }): string | { owners: string[]; weights: string[] } {
  const lines = state.owners.split("\n").filter(line => line.length > 0);
  if (lines.length === 0) {
    return "Must consist at least one line";
  } else {
    const result: { owners: string[]; weights: string[] } = { owners: [], weights: [] };
    for (const line of lines) {
      const splitted = line.trim().split(",");
      if (splitted.length !== 2) {
        return "All lines must consist a single comma";
      } else {
        const [addr, weight] = splitted.map(s => s.trim());
        if (!addr.match(/^0[x][0-9a-fA-F]{40}$/)) {
          return "The left side of them comma must be an address";
        } else if (addr === "0x0000000000000000000000000000000000000000") {
          return "Owner can't be null";
        } else if (!weight.match(/^[1-9]+\d*$/)) {
          return "The right side of the comma must be a non negative integer";
        } else if (Number(weight) >= 256) {
          return "The right side of the comma must be less than 256";
        } else if (result.owners.includes(addr)) {
          return "Addresses must be unique";
        } else {
          result.owners.push(addr);
          result.weights.push(weight);
        }
      }
    }
    return result;
  }
}

function DefaultContractsLoaded({ allDefaultContracts }: DefaultContractsLoadedProps) {
  const { chainId, account, library } = useWeb3React<Web3Provider>();
  if (!chainId) {
    throw new TypeError("chainId is falsy");
  }
  if (!account) {
    throw new TypeError("account is falsy");
  }
  const [savedContracts, setSavedContracts] = useState<Record<ContractName, ContractAddress>>(
    () => {
      const contractsOrNull = window.localStorage.getItem("contracts");
      return contractsOrNull
        ? (JSON.parse(contractsOrNull) as ChainIdToContracts)[chainId] ?? {}
        : {};
    }
  );

  const contracts = {
    ...(allDefaultContracts[chainId] || {}),
    ...savedContracts,
  };
  const contractAddresses = Object.values(contracts);
  const firstNetwork = contractAddresses[0] ?? "";
  // the value of the selector
  const [selectedAddress, setSelectedAddress] = useState(firstNetwork);
  // the contract address we actually use
  const currentAddress =
    selectedAddress === "add" || contractAddresses.includes(selectedAddress)
      ? selectedAddress
      : /* selectedAddress may point to a non existing contract due to network change */
        firstNetwork;
  const contractAlreadyExists = (name: string) => Object.keys(contracts).includes(name);
  const onAddressAdd = (name: string, address: string) => {
    if (contractAlreadyExists(name)) {
      alert("There is already a contract named " + name);
      return;
    }
    if (contractAddresses.includes(address)) {
      alert("There is already a contract with address " + address);
      return;
    }
    const newContracts = (() => {
      const parsedContracts: ChainIdToContracts = (() => {
        const cs = window.localStorage.getItem("contracts");
        return cs ? JSON.parse(cs) : {};
      })();
      return {
        ...parsedContracts,
        [chainId]: { ...(parsedContracts[chainId] ?? {}), [name]: address },
      };
    })();
    window.localStorage.setItem("contracts", JSON.stringify(newContracts));
    setSavedContracts(newContracts[chainId]);
    setSelectedAddress(address);
  };
  return (
    <>
      <div className="row">
        <div
          className="col text-center"
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            padding: "20px",
            paddingBottom: "0px",
            paddingLeft: "35px",
          }}
        >
          Choose contract to communicate with:
        </div>
      </div>
      <div className="row text-center" style={{ padding: "20px", paddingBottom: "0px" }}>
        <div className="col-2"></div>
        <div className="col-8 mb-3">
          <select
            className="form-select"
            id="inputGroupSelect01"
            value={currentAddress}
            onChange={e => setSelectedAddress(e.target.value)}
          >
            <option value="" disabled>
              Choose...
            </option>
            <option value="add">Add new</option>
            {Object.entries(contracts).map(([name, addr]) => (
              <option key={addr} value={addr}>
                {name}: {addr}
              </option>
            ))}
          </select>
        </div>
        <div className="col-2"></div>
      </div>
      {currentAddress === "add" && (
        <>
          <AddAddress onAddressAdd={onAddressAdd} />
          <NewContract onAddressAdd={onAddressAdd} contractAlreadyExists={contractAlreadyExists} />
        </>
      )}
      {!["", "add"].includes(currentAddress) && (
        <ShowContract address={currentAddress} account={account} library={library!} />
      )}
    </>
  );
}
