import { useWeb3React } from "@web3-react/core";
import { addNetwork, networks } from "../utils/EthereumUtils";
import { Web3Provider } from "@ethersproject/providers";
import Style from "./AddNetwork.module.css";
const qmark = "/images/question_mark.png";

export default function AddNetworkButtons() {
  return (
    <>
      <div className={"container mt-3 mb-3 " + Style.main_cont} style={{ maxWidth: "600px" }}>
        <div className="row mb-3 text-center">
          <div className={"col-1 " + Style.tooltip}>
            <img src={qmark} width="30" alt="" />
            <span className={Style.tooltiptext}>
              On this interface, you can conveniently add new networks to your Metamask wallet.
              Choose the desired network to add it, then proceed to the Metamask pop-up window.
            </span>
          </div>
          <div className="col-10">
            <h3>Choose network to add</h3>
          </div>
          <div className="col-1"></div>
        </div>
        <div> </div>
        <div className={"container " + Style.cont_s}>
          <div className="row justify-content-center m-3">
            <div className="col">
              <AddNetworkButton networkId="bnb" />
            </div>
            <div className="col">
              <AddNetworkButton networkId="bnbt" />
            </div>
          </div>
          <div className="row justify-content-center m-3">
            <div className="col text-center">
              <AddNetworkButton networkId="ilg" />
            </div>
            <div className="col text-center">
              <AddNetworkButton networkId="ilgt" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function AddNetworkButton({ networkId }: { networkId: keyof typeof networks }) {
  const { library } = useWeb3React<Web3Provider>();
  const network = networks[networkId];
  return (
    <button
      className="btn btn-outline-secondary"
      onClick={() => {
        addNetwork(library!, network);
      }}
    >
      Add {network.chainName}
    </button>
  );
}
