import { useFormik } from "formik";
import * as E from "fp-ts/es6/Either";
import { identity, pipe } from "fp-ts/es6/function";
import { test } from "fp-ts-contrib/es6/RegExp";
import { SetStateAction, useState } from "react";
import Big from "big.js";
import { trim } from "fp-ts/es6/string";
import { ContractFactory } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { green, red } from "@mui/material/colors";
import { useEffect } from "react";
import { parseEther } from "ethers/lib/utils";
import Style from "./TokenFactory.module.css";
import {
  Eip3085Chain as Network,
  networks,
  networksByChainId,
  setNetwork,
} from "../utils/EthereumUtils";
import { useSnackbar } from "notistack";
const qmark = "/images/question_mark.png";

type SupplyType = "Fixed" | "Capped" | "Unlimited";

export type Token = {
  name: string;
  supplyType: SupplyType;
  pausable: boolean;
  burnable: boolean;
  /** price in Eth */
  usdPrice: number;
};
export const tokens: Token[] = (
  [
    ["1", "Fixed", false, false, 1],
    ["2", "Fixed", false, true, 2],
    ["3", "Fixed", true, true, 3],
    ["4", "Capped", false, false, 4],
    ["5", "Capped", true, false, 5],
    ["6", "Capped", false, true, 6],
    ["7", "Unlimited", false, true, 7],
    ["8", "Unlimited", true, true, 8],
  ] as const
).map(([name, supplyType, pausable, burnable, usdPrice]) => ({
  name,
  supplyType,
  pausable,
  burnable,
  usdPrice,
}));

export const mintable = ({ supplyType }: Token) => supplyType !== "Fixed";
const ownable = (t: Token) => true;

const coinGecko = (n: Network) => {
  switch (n.chainId) {
    case networks.bnb.chainId:
    case networks.bnbt.chainId:
      return "binancecoin";
    case networks.eth.chainId:
    case networks.rop.chainId:
    case networks.kov.chainId:
    case networks.rin.chainId:
    case networks.gor.chainId:
      return "ethereum";
    default:
      throw new Error(`Unhandled network: ${n.chainName}`);
  }
};

const showBool = (b: boolean) => {
  const style = {
    color: (b ? green : red)[500],
  };
  const Icon = b ? CheckCircle : Cancel;

  return <Icon style={style} />;
};

// how much usd does one crypto worth
type CryptoInUsd =
  | { t: "loading"; controller?: AbortController }
  | { t: "fail" }
  | { t: "success"; value: string };

export default function TokenFactory() {
  const { enqueueSnackbar } = useSnackbar();
  const { chainId, library } = useWeb3React<Web3Provider>();
  const getNetwork = (chainId: string) => networksByChainId[chainId] ?? null;
  const selectedNetwork = getNetwork("0x" + chainId!.toString(16));
  const [cryptoInUsd, setCryptoInUsd] = useState<CryptoInUsd>({ t: "loading" });
  useEffect(() => {
    if (selectedNetwork) {
      if (cryptoInUsd.t === "loading") {
        cryptoInUsd.controller?.abort();
      }
      const controller = new AbortController();
      setCryptoInUsd({ t: "loading", controller });
      const [url, usdPrice]: [string, (response: any) => string] = [
        networks.ilg,
        networks.ilgt,
      ].includes(selectedNetwork)
        ? ["https://priceapi.ilgonwallet.com/prices", r => r.data.ILG_USD]
        : [
            `https://api.coingecko.com/api/v3/coins/${coinGecko(
              selectedNetwork
            )}?localization=false&tickers=false&community_data=false&developer_data=false`,
            r => r.market_data.current_price.usd,
          ];
      fetch(url, {
        signal: controller.signal,
      })
        .then(r => r.json())
        .then(usdPrice)
        .then(
          value => setCryptoInUsd({ t: "success", value }),
          (e: Error) => {
            if (e.name !== "AbortError") {
              enqueueSnackbar(e.message, { variant: "error" });
              setCryptoInUsd({ t: "fail" });
            }
          }
        );
    }
  }, [selectedNetwork]); // eslint-disable-line react-hooks/exhaustive-deps
  const [selectedToken, setSelectedToken] = useState<Token | null>(null);
  return (
    <div className={"container mt-3 mb-3 " + Style.main_cont}>
      <div className="row mb-3">
        <div
          className={
            "col-1 d-flex flex-wrap align-items-center justify-content-center " + Style.tooltip
          }
        >
          <img src={qmark} width="30" alt="" />
          <span className={Style.tooltiptext}>
            On this interface you can easily create all sorts of ERC-20 and BEP-20 standard tokens.
            Choose the network you wish to mint the tokens to, select the type that suits you best,
            then provide the required parameters, to finish the minting.
          </span>
        </div>
        <div className="col align-self-center">
          <h1 style={{ margin: "0" }}>Token Factory</h1>
        </div>
      </div>

      <div style={{ marginLeft: "4px" }} className="row mb-3">
        <div className={"col-lg-auto " + Style.cont_s}>
          <div className="col col-4 m-3">
            <div style={{ margin: "0", fontSize: "25px" }}>Network:</div>
          </div>
          <div className="col col-7  align-self-center">
            <form style={{ width: "100%" }}>
              <select
                className={"form-select form-select " + Style.sel1}
                style={{ width: "100%" }}
                value={selectedNetwork?.chainId ?? "unknown"}
                onChange={({ target: { value } }) =>
                  setNetwork(library!, networksByChainId[value as string]!)
                }
              >
                <option hidden disabled value="unknown">
                  Please select a network
                </option>
                {Object.values(networks).map(({ chainId, chainName }) => (
                  <option key={chainId} value={chainId}>
                    {chainName}
                  </option>
                ))}
              </select>
            </form>
          </div>
        </div>
      </div>

      {selectedNetwork && (
        <TokenTable
          network={selectedNetwork}
          cryptoInUsd={cryptoInUsd}
          setSelectedToken={setSelectedToken}
        />
      )}
      {/* we cannot unmount this component
          1. we don't want values in the form to be lost
          2. an async action could be happening */}
      <div style={{ display: selectedNetwork ? "revert" : "none" }}>
        <AddTokenForm cryptoInUsd={cryptoInUsd} selectedToken={selectedToken} />
      </div>
    </div>
  );
}

export type StoredToken = {
  address: string;
  type: string;
  symbol: string;
  decimals: number;
  chainId: number;
  name: string;
};

function AddTokenForm({
  cryptoInUsd,
  selectedToken,
}: {
  cryptoInUsd: CryptoInUsd;
  selectedToken: Token | null;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const cappedSelected = selectedToken?.supplyType === "Capped";
  type RpcError = {
    error: {
      message: string;
    };
  };
  const { library, chainId } = useWeb3React<Web3Provider>();
  if (!chainId) {
    throw new Error();
  }
  const formik = useFormik({
    initialValues: {
      name: "",
      symbol: "",
      decimals: "18",
      initialSupply: "",
      supplyCap: "",
    },
    onSubmit: async values => {
      if (cryptoInUsd.t !== "success") {
        throw new Error();
      }
      const p = fetch(process.env.PUBLIC_URL + `/contracts/T${selectedToken!.name}.json`)
        .then(resp => resp.json())
        .then(compilerOutput =>
          ContractFactory.fromSolidity(compilerOutput, library!.getSigner()).deploy(
            values.name,
            values.symbol,
            values.decimals,
            Big(10 ** Number(values.decimals))
              .mul(values.initialSupply)
              .toFixed(),
            "0x288cb755bD8Ef6aF16602F932Bd020D2C6706608",
            ...(cappedSelected
              ? [
                  Big(10 ** Number(values.decimals))
                    .mul(values.supplyCap)
                    .toFixed(),
                ]
              : []),
            {
              value: parseEther(
                Big(selectedToken!.usdPrice).div(cryptoInUsd.value).round(18).toFixed()
              ),
            }
          )
        )
        .then(async ({ deployTransaction, address }) => {
          await deployTransaction.wait();
          const withTokens = (f: (ts: StoredToken[]) => StoredToken[]) =>
            window.localStorage.setItem(
              "tokens",
              JSON.stringify(
                f(JSON.parse(window.localStorage.getItem("tokens") ?? "[]") as StoredToken[])
              )
            );
          withTokens(ts => {
            ts.push({
              address,
              decimals: Number(values.decimals),
              type: selectedToken!.name,
              symbol: values.symbol,
              chainId,
              name: values.name,
            });
            return ts;
          });
          enqueueSnackbar(
            `Successful token creation at ${address}. You can manage your token by clicking the Token Manager menu.`,
            { variant: "success" }
          );
        });
      return p.catch((error: Error | RpcError) => {
        // because formik catches the error
        window.dispatchEvent(
          new PromiseRejectionEvent("unhandledrejection", { reason: error, promise: p })
        );
      });
    },
    validate: values => {
      const required = E.fromPredicate(
        (s: string) => s.length > 0,
        () => "Required"
      );

      const getError = (prop: string) =>
        E.match(
          e => ({ [prop]: e }),
          () => ({})
        );

      const name = pipe(values.name, trim, required, getError("name"));
      const symbol = pipe(values.symbol, required, getError("symbol"));
      const decimals = pipe(
        values.decimals,
        required,
        E.filterOrElse(test(/^\d+$/), () => "Must be a non negative integer"),
        E.map(Number),
        E.filterOrElse(
          d => d < 256,
          () => "Must be less than 256"
        )
      );

      const validateDigits = pipe(
        decimals,
        E.match(
          () => identity,
          d =>
            E.filterOrElse(
              (b: Big) => b.c.length - (b.e + 1) <= d,
              () => "The number of digits must be at most decimals"
            )
        )
      );
      const fitsInUint = pipe(
        decimals,
        E.match(
          () => identity,
          d =>
            E.filterOrElse(
              (b: Big) => b.times(10 ** d).lt(Big(2).pow(256)),
              () => "Too large (has to fit in uint256)"
            )
        )
      );

      const initialSupply = pipe(
        values.initialSupply,
        required,
        E.map(s => Big(s)),
        E.filterOrElse(
          s => s.gte(0),
          () => "Must be a non negative number"
        ),
        validateDigits,
        fitsInUint
      );

      const atLeastInitialSupply = pipe(
        initialSupply,
        E.match(
          () => identity,
          s =>
            E.filterOrElse(
              (c: Big) => c.gte(s),
              () => "Must be at least Initial Supply"
            )
        )
      );
      const supplyCap = () =>
        pipe(
          values.supplyCap,
          required,
          E.map(c => Big(c)),
          E.filterOrElse(
            c => c.gt(0),
            () => "Must be a positive number"
          ),
          validateDigits,
          fitsInUint,
          atLeastInitialSupply,
          getError("supplyCap")
        );

      return {
        ...name,
        ...symbol,
        ...getError("decimals")(decimals),
        ...getError("initialSupply")(initialSupply),
        ...(cappedSelected ? supplyCap() : {}),
      };
    },
  });
  return (
    <>
      <div style={{ marginLeft: "4px" }} className={"row mb-3"}>
        <div className={"col-lg-auto " + Style.cont_s}>
          <div className="col m-3">
            <div style={{ margin: "0", fontSize: "25px" }}>
              {selectedToken ? `Token type: ${selectedToken.name}` : "Select a token type"}
            </div>
          </div>
        </div>
      </div>

      {selectedToken && (
        <div className="form1">
          <form onSubmit={formik.handleSubmit}>
            <div className="row mb-3">
              <div className="col">
                <label className="form-label">Name</label>
                <div className="input-group has-validation">
                  <input
                    name="name"
                    className={`form-control ${Style.inp1} ${
                      formik.errors.name ? "is-invalid" : ""
                    }`}
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  <div className="invalid-feedback">{formik.errors.name}</div>
                </div>
              </div>
              <div className="col">
                <label className="form-label">Symbol</label>
                <div className="input-group has-validation">
                  <input
                    name="symbol"
                    className={`form-control ${Style.inp1} ${
                      formik.errors.symbol ? "is-invalid" : ""
                    }`}
                    disabled={formik.isSubmitting}
                    onChange={e => {
                      formik.setFieldValue(
                        "symbol",
                        e.target.value.replace(/\s+/g, "").toUpperCase()
                      );
                    }}
                    value={formik.values.symbol}
                  />
                  <div className="invalid-feedback">{formik.errors.symbol}</div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label className="form-label">Decimals</label>
                <div className="input-group has-validation">
                  <input
                    name="decimals"
                    className={`form-control ${Style.inp1} ${
                      formik.errors.decimals ? "is-invalid" : ""
                    }`}
                    disabled={formik.isSubmitting}
                    type="number"
                    onChange={e => {
                      formik.setFieldValue("decimals", e.target.value);
                    }}
                    value={formik.values.decimals}
                  />
                  <div className="invalid-feedback">{formik.errors.decimals}</div>
                </div>
              </div>
              <div className="col">
                <label className="form-label">Initial Supply</label>
                <div className="input-group has-validation">
                  <input
                    name="initialSupply"
                    className={`form-control ${Style.inp1} ${
                      formik.errors.initialSupply ? "is-invalid" : ""
                    }`}
                    disabled={formik.isSubmitting}
                    type="number"
                    onChange={e => {
                      formik.setFieldValue("initialSupply", e.target.value);
                    }}
                    value={formik.values.initialSupply}
                  />
                  <div className="invalid-feedback">{formik.errors.initialSupply}</div>
                </div>
              </div>
            </div>
            {cappedSelected && (
              <div className="row mb-3">
                <div className="col-6">
                  <label className="form-label">Supply Cap</label>
                  <div className="input-group has-validation">
                    <input
                      className={`form-control ${Style.inp1} ${
                        formik.errors.supplyCap ? "is-invalid" : ""
                      }`}
                      disabled={formik.isSubmitting}
                      type="number"
                      onChange={e => {
                        formik.setFieldValue("supplyCap", e.target.value);
                      }}
                      value={formik.values.supplyCap}
                    />
                    <div className="invalid-feedback">{formik.errors.supplyCap}</div>
                  </div>
                </div>
              </div>
            )}
            <div className="row justify-content-center">
              <button
                style={{ maxWidth: "500px", color: "white" }}
                className={"btn btn-outline-secondary"}
                disabled={formik.isSubmitting || cryptoInUsd.t !== "success"}
                type="submit"
                color="white"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
function TokenTable({
  network,
  cryptoInUsd,
  setSelectedToken,
}: {
  network: Network;
  cryptoInUsd: CryptoInUsd;
  setSelectedToken: (a: SetStateAction<Token | null>) => void;
}) {
  return (
    <div className={"container " + Style.table1}>
      <table className="table table-dark table-hover">
        <tbody style={{ textAlign: "center" }}>
          <tr>
            <td></td>
            {tokens.map(t => (
              <td align="center" key={t.name}>
                {t.name}
              </td>
            ))}
          </tr>
          <tr>
            <td align="center">Supply Type</td>
            {tokens.map(t => (
              <td align="center" key={t.name}>
                {t.supplyType}
              </td>
            ))}
          </tr>
          <tr>
            <td align="center">Transfer Type</td>
            {tokens.map(t => (
              <td align="center" key={t.name}>
                {t.pausable ? "" : "Non-"}Pausable
              </td>
            ))}
          </tr>
          <tr>
            <td align="center">Mintable</td>
            {tokens.map(t => (
              <td align="center" key={t.name}>
                {pipe(t, mintable, showBool)}
              </td>
            ))}
          </tr>
          <tr>
            <td align="center">Burnable</td>
            {tokens.map(t => (
              <td align="center" key={t.name}>
                {showBool(t.burnable)}
              </td>
            ))}
          </tr>
          <tr>
            <td align="center">Ownable</td>
            {tokens.map(t => (
              <td align="center" key={t.name}>
                {showBool(ownable(t))}
              </td>
            ))}
          </tr>
          <tr>
            <td align="center">Price</td>
            {tokens.map(t => (
              <td align="center" key={t.name}>
                <Price token={t} cryptoUsdValue={cryptoInUsd} network={network} />
              </td>
            ))}
          </tr>
          <tr>
            <td align="center"></td>
            {tokens.map(t => (
              <td align="center" key={t.name}>
                <button
                  style={{ color: "white" }}
                  className={"btn btn-outline-secondary"}
                  onClick={() => setSelectedToken(t)}
                >
                  Select
                </button>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
function Price({
  token,
  cryptoUsdValue,
  network,
}: {
  token: Token;
  cryptoUsdValue: CryptoInUsd;
  network: Network;
}) {
  const shownCryptoInUsd = (() => {
    switch (cryptoUsdValue.t) {
      case "loading":
        return "...";
      case "fail":
        return "N/A";
      case "success":
        return `~${Big(token.usdPrice).div(cryptoUsdValue.value).round(4).toFixed()}`;
    }
  })();
  return <>{`${token.usdPrice}$ (${shownCryptoInUsd} ${network.nativeCurrency.symbol})`}</>;
}
