import { useState, Fragment, ReactNode, ReactElement } from "react";
export type MultiMap<K, V> = readonly [K, readonly V[]][];
export function GroupSelector<Group, Value>({
  values,
  Button,
  DropdownContainer,
  SeparatorComponent,
  GroupComponent,
  ValueComponent,
}: {
  values: MultiMap<Group, Value>;
  Button: (props: { toggleOpen: () => void }) => ReactElement;
  DropdownContainer: (props: { open: boolean; children: ReactNode }) => ReactElement;
  SeparatorComponent: () => ReactElement;
  GroupComponent: (props: { group: Group }) => ReactElement;
  ValueComponent: (props: { group: Group; value: Value }) => ReactElement;
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button toggleOpen={() => setOpen(!open)} />
      <DropdownContainer open={open}>
        {values.map(([group, values], i) => (
          <Fragment key={i}>
            {i !== 0 && <SeparatorComponent />}
            <GroupComponent group={group} />
            {values.map((value, i) => (
              <ValueComponent key={i} group={group} value={value} />
            ))}
          </Fragment>
        ))}
      </DropdownContainer>
    </>
  );
}
