import { Web3Provider } from "@ethersproject/providers";
import { identity } from "fp-ts/es6/function";
import ilgonImg from "./resources/ilgon.png";

// from https://docs.metamask.io/guide/rpc-api.html#parameters-4

export type HexString = `0x${string}`;
export type Eip3085Chain = Readonly<{
  chainId: HexString;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: 18;
  };
  blockExplorerUrls?: [string];
  iconUrls?: [string];
  rpcUrls?: string[]; // we assume the ethereum provider knows the network
}>;
export type Eip747Asset = Readonly<{
  type: string; // The asset's interface, e.g. 'ERC20'
  options: {
    address: HexString; // The hexadecimal Ethereum address of the token contract
    symbol: string; // A ticker symbol or shorthand, up to 5 alphanumerical characters
    decimals: number; // The number of asset decimals
    image: string; // A string url of the token logo
  };
}>;

/** based on https://docs.metamask.io/guide/rpc-api.html#usage-with-wallet-switchethereumchain */
export async function setNetwork(library: Web3Provider, network: Eip3085Chain): Promise<void> {
  const request = library.provider.request!;
  return request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: network.chainId }],
  })
    .catch((e: Error & { code?: number }) => {
      // This error code indicates that the chain has not been added to MetaMask.
      if (e.code === 4902) {
        return addNetwork(library, network);
      } else {
        throw e;
      }
    })
    .catch((e: Error & { code?: number }) => {
      // / EIP-1193 userRejectedRequest error
      if (e.code === 4001) {
        return setNetwork(library, network);
      } else {
        throw e;
      }
    });
}

export async function addNetwork(library: Web3Provider, network: Eip3085Chain): Promise<void> {
  const request = library.provider.request!;
  return request({
    method: "wallet_addEthereumChain",
    params: [network],
  });
}

// ha 0 vagy negativ akkor ne csinaljon semmit es akkor se ha ures

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ilgonTest = identity<Eip3085Chain>({
  chainId: "0x696c6774",
  chainName: "ILGON Testnet",
  rpcUrls: ["https://testnet-rpc.ilgonwallet.com"],
  nativeCurrency: {
    name: "ILGON_TEST",
    symbol: "ILGT",
    decimals: 18,
  },
  blockExplorerUrls: ["https://testnet.ilgonexplorer.com"],
  iconUrls: [ilgonImg],
});

export const networks = {
  ilgt: ilgonTest,
  ilg: identity<Eip3085Chain>({
    chainId: "0x696c67",
    chainName: "ILGON Mainnet",
    rpcUrls: ["https://mainnet-rpc.ilgonwallet.com"],
    nativeCurrency: {
      name: "ILGON",
      symbol: "ILG",
      decimals: 18,
    },
    blockExplorerUrls: ["https://ilgonexplorer.com/"],
    iconUrls: [ilgonImg],
  }),
  eth: identity<Eip3085Chain>({
    chainId: "0x1",
    chainName: "Ethereum Mainnet",
    rpcUrls: ["https://api.mycryptoapi.com/eth", "https://cloudflare-eth.com"],
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    iconUrls: [ilgonImg],
  }),
  rop: identity<Eip3085Chain>({
    chainId: "0x3",
    chainName: "Ethereum Testnet Ropsten",
    nativeCurrency: { name: "Ropsten Ether", symbol: "ROP", decimals: 18 },
  }),
  kov: identity<Eip3085Chain>({
    chainId: "0x2a",
    chainName: "Ethereum Testnet Kovan",
    rpcUrls: [
      "https://kovan.poa.network",
      "http://kovan.poa.network:8545",
      "ws://kovan.poa.network:8546",
    ],
    nativeCurrency: { name: "Kovan Ether", symbol: "KOV", decimals: 18 },
  }),
  rin: identity<Eip3085Chain>({
    chainId: "0x4",
    chainName: "Ethereum Testnet Rinkeby",
    nativeCurrency: { name: "Rinkeby Ether", symbol: "RIN", decimals: 18 },
    blockExplorerUrls: ["https://rinkeby.etherscan.io"],
  }),
  gor: identity<Eip3085Chain>({
    chainId: "0x5",
    chainName: "Ethereum Testnet Görli",
    rpcUrls: [
      "https://rpc.goerli.mudit.blog/",
      "https://rpc.slock.it/goerli",
      "https://goerli.prylabs.net/",
    ],
    nativeCurrency: { name: "Görli Ether", symbol: "GOR", decimals: 18 },
    blockExplorerUrls: ["https://goerli.etherscan.io"],
  }),
  bnb: identity<Eip3085Chain>({
    chainId: "0x38",
    chainName: "Binance Smart Chain Mainnet",
    rpcUrls: [
      "https://bsc-dataseed1.binance.org",
      "https://bsc-dataseed2.binance.org",
      "https://bsc-dataseed3.binance.org",
      "https://bsc-dataseed4.binance.org",
      "https://bsc-dataseed1.defibit.io",
      "https://bsc-dataseed2.defibit.io",
      "https://bsc-dataseed3.defibit.io",
      "https://bsc-dataseed4.defibit.io",
      "https://bsc-dataseed1.ninicoin.io",
      "https://bsc-dataseed2.ninicoin.io",
      "https://bsc-dataseed3.ninicoin.io",
      "https://bsc-dataseed4.ninicoin.io",
      "wss://bsc-ws-node.nariox.org",
    ],
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "BNB",
      decimals: 18,
    },
    blockExplorerUrls: ["https://bscscan.com"],
    iconUrls: ["https://bin.bnbstatic.com/static/images/common/favicon.ico"],
  }),
  bnbt: identity<Eip3085Chain>({
    chainId: "0x61",
    chainName: "Binance Smart Chain Testnet",
    rpcUrls: [
      "https://data-seed-prebsc-1-s1.binance.org:8545",
      "https://data-seed-prebsc-2-s1.binance.org:8545",
      "https://data-seed-prebsc-1-s2.binance.org:8545",
      "https://data-seed-prebsc-2-s2.binance.org:8545",
      "https://data-seed-prebsc-1-s3.binance.org:8545",
      "https://data-seed-prebsc-2-s3.binance.org:8545",
    ],
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "tBNB",
      decimals: 18,
    },
    blockExplorerUrls: ["https://testnet.bscscan.com"],
    iconUrls: ["https://bin.bnbstatic.com/static/images/common/favicon.ico"],
  }),
} as const;

export const networksByChainId = Object.fromEntries(
  Object.values(networks).map(n => [n.chainId, n])
);
