import { createRef, useEffect, useState } from "react";
import { UnsupportedChainIdError, useWeb3React, Web3ReactProvider } from "@web3-react/core";
import {
  InjectedConnector,
  NoEthereumProviderError,
  UserRejectedRequestError,
} from "@web3-react/injected-connector";
import { Web3Provider } from "@ethersproject/providers";

import { ExternalProvider, JsonRpcFetchFunc } from "@ethersproject/providers/src.ts/web3-provider";
import Onboarding from "@metamask/onboarding";
import { BrowserRouter as Router, Routes, Route, Navigate, NavLink } from "react-router-dom";
import Bridge from "./bridge/Bridge";
import TokenFactory from "./token_factory/TokenFactory";

import Style from "./app.module.css";
import Loader from "./components/loader";
import TokenManager from "./token-manager/TokenManager";
import Token from "./token-manager/Token";
import { SnackbarProvider, useSnackbar } from "notistack";
import Multisig from "./multisig/multisig";
import AddNetwork from "./add-network/AddNetwork";
const coin = "/images/coin.png";
const coins = "/images/coins.png";
const crosschain = "/images/crosschain.svg";
const pancakeimg = "/images/pancakeswap.svg";
const multisig = "/images/multisig.png";
const bridge = "/images/bridge.png";
function RouterComponent() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <Router>
      <div
        style={{ backgroundColor: "black" }}
        className={sidebarOpen ? Style["sb-sidenav-toggled"] : ""}
      >
        <div className="d-flex" id={Style["wrapper"]}>
          {/* Sidebar*/}
          <div className="border-end" id={Style["sidebar-wrapper"]}>
            <div className={Style["sidebar-heading"] + " border-bottom"}>
              <h2>Menu</h2>
            </div>
            <div className={Style["list-group"] + "list-group-flush"}>
              <NavLink
                to="/add-network"
                style={{ textDecoration: "none" }}
                className={Style.nav_hover}
              >
                <div className={Style.nav_1}>
                  <div>
                    <img src={crosschain} width="40" className={Style.logo} alt="" />
                  </div>
                  <div className={Style.side_text}> Add Network </div>
                </div>
              </NavLink>
              <NavLink
                to="/token-factory"
                style={{ textDecoration: "none" }}
                className={Style.nav_hover}
              >
                <div className={Style.nav_1}>
                  <div>
                    <img src={coins} width="40" className={Style.logo} alt="" />
                  </div>
                  <div className={Style.side_text}> Token Factory </div>
                </div>
              </NavLink>
              <NavLink
                to="/token-manager"
                style={{ textDecoration: "none" }}
                className={Style.nav_hover}
              >
                <div className={Style.nav_1}>
                  <div>
                    <img src={coin} width="40" className={Style.logo} alt="" />
                  </div>
                  <div className={Style.side_text}> Token Manager </div>
                </div>
              </NavLink>

              <NavLink to="/bridge" style={{ textDecoration: "none" }} className={Style.nav_hover}>
                <div className={Style.nav_1}>
                  <div>
                    <img src={bridge} width="40" className={Style.logo} alt="" />
                  </div>
                  <div className={Style.side_text}> Bridge </div>
                </div>
              </NavLink>
              <a
                href="/pancake.html"
                style={{ textDecoration: "none" }}
                className={Style.nav_hover}
              >
                <div className={Style.nav_1}>
                  <div>
                    <img src={pancakeimg} width="40" className={Style.logo} alt="" />
                  </div>
                  <div className={Style.side_text}> PancakeSwap </div>
                </div>
              </a>
              <NavLink
                to="/multisig"
                style={{ textDecoration: "none" }}
                className={Style.nav_hover}
              >
                <div className={Style.nav_1}>
                  <div>
                    <img src={multisig} width="40" className={Style.logo} alt="" />
                  </div>
                  <div className={Style.side_text}> Multisig Wallet </div>
                </div>
              </NavLink>
            </div>
          </div>
          {/* Page content wrapper*/}
          <div id={Style["page-content-wrapper"]}>
            {/* Top navigation*/}
            <nav className={"navbar navbar-expand-lg navbar-dark " + Style.topcont}>
              <div className="container-fluid">
                <button
                  style={{ backgroundColor: "#383848" }}
                  className={"btn " + Style.togglebtn}
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                  id="sidebarToggle"
                >
                  <span className="navbar-toggler-icon" />
                </button>
              </div>
            </nav>
            {/* Page content*/}
            <div className="container-fluid">
              <Routes>
                <Route
                  path="/add-network"
                  element={
                    <InjectedLogin>
                      <AddNetwork />
                    </InjectedLogin>
                  }
                />
                <Route
                  path="/bridge"
                  element={
                    <InjectedLogin>
                      <Bridge />
                    </InjectedLogin>
                  }
                />
                <Route
                  path="/token-factory"
                  element={
                    <InjectedLogin>
                      <TokenFactory />
                    </InjectedLogin>
                  }
                />
                <Route
                  path="/token-manager/:address"
                  element={
                    <InjectedLogin>
                      <Token />
                    </InjectedLogin>
                  }
                />
                <Route path="/token-manager" element={<TokenManager />} />
                <Route
                  path="/multisig"
                  element={
                    <InjectedLogin>
                      <Multisig />
                    </InjectedLogin>
                  }
                />
                <Route path="*" element={<Navigate to="/add-network" />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

function getErrorMessage(error: Error) {
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network.";
  } else if (error instanceof UserRejectedRequestError) {
    return "Please authorize this website to access your Ethereum account.";
  } else {
    console.error(error);
    return "An unknown error occurred. Check the console for more details.";
  }
}

function getLibrary(provider: ExternalProvider | JsonRpcFetchFunc): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

export default function App() {
  // add action to all snackbars
  const notistackRef = createRef<SnackbarProvider>();
  return (
    <SnackbarProvider
      style={{ width: "30vw" }}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      ref={notistackRef}
      autoHideDuration={30_000}
      action={key => (
        <button className="btn" onClick={() => notistackRef.current!.closeSnackbar(key)}>
          Dismiss
        </button>
      )}
    >
      <AppUsingSnackProvider />
    </SnackbarProvider>
  );
}

function AppUsingSnackProvider() {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const addError = (e: string) => enqueueSnackbar(e, { variant: "error" });
    window.addEventListener("unhandledrejection", ({ reason }: PromiseRejectionEvent) => {
      // web3-provider-engine causes it and couldn't find why
      if (reason.message !== "Callback was already called.") {
        console.log(reason);
        addError(reason?.data?.message ?? reason.message);
      }
    });
    window.onerror = (_, __, ___, ____, error) => {
      if (error) {
        addError(error.message);
      }
      return false;
    };
  }, [enqueueSnackbar]);
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <RouterComponent />
    </Web3ReactProvider>
  );
}

function InjectedLogin({ children }: { children: JSX.Element }) {
  const { active, error, activate, account } = useWeb3React<Web3Provider>();
  useEffect(() => {
    activate(new InjectedConnector({})).then();
  }, [activate]);
  return active ? (
    <>
      <div className={"container font-weight-bold mb-3 " + Style.cont1}>
        <h4>
          Connected: <span className={Style.bluetext}> {account}</span>
        </h4>
      </div>
      {children}
    </>
  ) : error instanceof NoEthereumProviderError ? (
    <InstallMetaMaskButton />
  ) : error ? (
    <div style={{ margin: "400px", color: "#0d6efd", borderColor: "#0d6efd" }}>
      {getErrorMessage(error)}
    </div>
  ) : (
    <Loader style={{ margin: "auto" }} />
  );
}

function InstallMetaMaskButton() {
  const [onboarding, setOnboarding] = useState(false);
  return onboarding ? (
    <div className="row" style={{ width: "300px", margin: "auto" }}>
      <div className="col align-self-center">
        <button
          disabled
          className="btn btn-outline-primary"
          style={{ marginTop: "200px", width: "300px", height: "50px", fontSize: "20px" }}
        >
          Onboarding in progress
        </button>
      </div>
    </div>
  ) : (
    <div className="row" style={{ width: "300px", margin: "auto" }}>
      <div className="col align-self-center">
        <button
          style={{ marginTop: "200px", width: "300px", height: "50px", fontSize: "20px" }}
          className="btn btn-outline-primary"
          onClick={() => {
            setOnboarding(true);
            new Onboarding().startOnboarding();
          }}
        >
          Install MetaMask
        </button>
      </div>
    </div>
  );
}
